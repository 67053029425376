import { useForm } from "react-hook-form";
import DashboardLayout from "../../components/layout/DashboardLayout"
import Button from "../../components/button/Button";
import { useReportCertificateTypeMutation } from "../../app/services/certificateDeath";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

import CertificateCountReport from "../../components/certificateReport/certificatecountReport/CertificatecountReport";

const CertificateUionReportpage = () => {
    //
    const componentRef = useRef();
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();
    // rtk handel 
    const [unionRepost, { data: unionData, isLoading: unionIsloadin }] = useReportCertificateTypeMutation()

    const SeacrhSubmit = async (searchValue) => {
        try {
            await unionRepost(searchValue);
        } catch (error) {
            console.error("Error submitting the search: ", error);
            alert("There was an error processing your request.");
        }
    };

    return (
        <DashboardLayout>

            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">
                            সনদের প্রতিবেদন
                        </h2>

                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                            <form
                                onSubmit={handleSubmit(SeacrhSubmit)}
                                style={{ display: "flex" }}
                            >
                                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                    <div>
                                        <p>হইতে</p>
                                    </div>
                                    <input
                                        id="fromDate"
                                        type="date"
                                        placeholder="অনুসন্ধান এনআইডি"
                                        className="form-control"
                                        {...register("fromDate", {
                                            required: "মাস-দিন-বছর",
                                        })}
                                    />
                                    {errors?.fromDate && (
                                        <span className="form__error">
                                            {errors?.fromDate.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                    <div>
                                        <p>পর্যন্ত</p>
                                    </div>
                                    <input
                                        id="toDate"
                                        type="date"
                                        className="form-control"
                                        {...register("toDate", {
                                            required: "মাস-দিন-বছর",
                                        })}
                                    />
                                    {errors?.toDate && (
                                        <span className="form__error">
                                            {errors?.toDate.message}
                                        </span>
                                    )}
                                </div>
                                {
                                    <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3  text-end">
                                        <Button
                                            text={'সাবমিট করুন'}
                                            isLoading={unionIsloadin}
                                        />

                                    </div>
                                }
                            </form>
                        </div>
                    </header>
                </div>
                {/* pdf formation */}
                {
                    unionData && <section>
                        <div className="mb-5  text-end">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="btn btn-md justify-content-center">
                                        প্রিন্ট করুন
                                    </button>
                                )}
                                content={() => componentRef.current}
                            />
                        </div>
                        <CertificateCountReport unionData={unionData} componentRef={componentRef}/>


                    </section>

                }













            </section>



        </DashboardLayout>
    )

}
export default CertificateUionReportpage;