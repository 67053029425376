import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styles from './EnNotunVoterSonod.module.css';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { connect } from 'react-redux';
import { CertificateFooterEn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderEn } from '../../CertificateHeader/CertificateHeader';
import { useEffect } from 'react';
import { RefetchData } from '../../../RefetchData';
import { useState } from 'react';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';

const EnNotunVoterSonod = ({ userInfo }) => {

  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);


  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}>
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderEn certificate={certificate} sonodName={'New Voter Prottoyon'} />
                  <div>
                    <p className={styles.mainText}>
                      This is to certify that,<b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id ?.name_english}</b>,
                      birth registration No:
                      <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.nid}</b>, Father/Husband:{' '}
                      <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_english}</b>, Mother:{' '}
                      <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.mother_name_english}</b>, His{' '}

                      <b style={{ fontWeight: 'bold' }}>Permanent Address: </b>  Village/House: {' '}
                      {certificate?.citizen_id?.permanent_address[0]?.village_en_name}, Road/Block/Sector:  {' '}
                      {certificate?.citizen_id?.permanent_address[0]?.road}, Post Office: {certificate?.citizen_id?.permanent_address[0]?.post_office_en},
                      Ward No: {certificate?.citizen_id?.permanent_address[0]?.ward_no},{' '}
                      {
                        accountType === 'municipality' ? `Municipal: ` : 'Union:'
                      }
                      {certificate?.union_or_municipality?.municipality_union_en_name}, {" "}
                      Upazila: {" "}
                      {certificate?.citizen_id?.permanent_address[0]?.upazila?.name}, District:{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.name}. {' '}

                      <b style={{ fontWeight: 'bold' }}>Present Address:</b>  Village/House: {' '}
                      {certificate?.citizen_id?.present_address[0]?.village_en_name}, Road/Block/Sector:  {' '}
                      {certificate?.citizen_id?.present_address[0]?.road}, Post Office: {certificate?.citizen_id?.permanent_address[0]?.post_office_en},
                      Ward No: {certificate?.citizen_id?.present_address[0]?.ward_no},{' '}
                      {
                        accountType === 'municipality' ? `Municipal: ` : 'Union:'
                      }
                      {certificate?.union_or_municipality?.municipality_union_en_name}, {" "}
                      Upazila: {" "}
                      {certificate?.citizen_id?.present_address[0]?.upazila?.name}, District:{' '}
                      {certificate?.citizen_id?.present_address[0]?.district?.name}. is a permanent resident of the
                      Union and a permanent resident of Bangladesh. She has been
                      issued a Citizenship Certificate and Tax Receipt(s) from the
                      Union Parishad and as far as I know she has never been a voter
                      anywhere before. Therefore, it may be proposed that she be
                      registered is as a new voter and given a national identity card.
                    </p>
                    <br />

                    <div className={styles.mainText}>
                      <p>I wish him all the best and a bright future. </p>
                    </div>
                  </div>
                </div>
                <CertificateFooterEn certificate={certificate} accountType={accountType} marginHandel={true} />
              </div>
              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>

          <div className="download_pdf d-flex justify-content-end me-4 my-5">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      }
    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(EnNotunVoterSonod)