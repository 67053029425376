import EnglishToBanglaNumber from "../EnglishToBanglaNumber";

export default function FiscalYear({ type }) {
    let format;
    const date = new Date();
    date.setMonth(date.getMonth() - 6);
    const year = date.getFullYear();
    const handelDate = `${year} - ${year + 1}`
    if (type == 'bangla') {
        format = EnglishToBanglaNumber(handelDate)
    } else {
        format = handelDate
    }
    return format;
}
