import styles from "./CertificatecountReport.module.css";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
import BnFormatDate from "../../../utils/DateDisplay/bnFormatDate";

const CertificateCountReport = ({componentRef,unionData}) => {
    return (

        <div
            ref={componentRef} className={styles.container_countReport}>
            <div>
                <header className={styles.headerContainer_countReport}>
                    <h1>{unionData?.userData?.union_bn_name}</h1>
                    <h3> {unionData?.userData?.upazila?.bn_name}, {unionData?.userData?.district?.bn_name}</h3>
                    <h4> সনদ ভিত্তিক রিপোর্ট (সকল)</h4>
                    <h5>{BnFormatDate(`${unionData?.date?.fromDate}`)} হইতে {BnFormatDate(`${unionData?.date?.toDate}`)}</h5>
                </header>
                {/* table section */}
                <table className={styles.table_countReport}>
                    <thead>
                        <tr className={styles.th_countReport}>
                            <th className={styles.th_countReport}><strong>সনদর নাম</strong></th>
                            <th className={styles.th_countReport}><strong>আবেদনকৃত</strong></th>
                            <th className={styles.th_countReport}><strong>অনুমোদিত</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>ট্রেড লাইসেন্স</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.tradelicense?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.tradelicense?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>পারিবারিক সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.family?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.family?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>ওয়ারিশ সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.theheir?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.theheir?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>মৃত্যু সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.death?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.death?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>জাতীয়তা সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.nationality?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.nationality?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>নাগরিকত্ব সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.citizenship?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.citizenship?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>নতুন ভোটার প্রত্যয়ন</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.newvoterattestation?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.newvoterattestation?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>পুনঃবিবাহ-সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.remarriage?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.remarriage?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>কৃষি প্রত্যয়ন</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.agricultural?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.agricultural?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>বার্ষিক আয়ের সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.annualincome?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.annualincome?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>মাসিক আয়ের সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.monthlyincome?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.monthlyincome?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>চারিত্রিক সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.characteristic?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.characteristic?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>ভোটার এলাকা স্থানান্তর প্রত্যয়ন</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.transferofconstituency?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.transferofconstituency?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>একই ব্যক্তির প্রত্যয়ন</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.attestationofthesameperson?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.attestationofthesameperson?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>অবিবাহিত সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.unmarried?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.unmarried?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}>অন্যান্য সনদ</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.other?.pendingCertificate}`)}</td>
                            <td className={styles.td_countReport}>{EnglishToBanglaNumber(`${unionData?.certificate?.other?.approvedCertificate}`)}</td>
                        </tr>
                        <tr className={styles.tr_countReport}>
                            <td className={styles.td_countReport}></td>
                            <td className={styles.td_countReport}>মোট = {EnglishToBanglaNumber(`${unionData?.totals?.totalPending}`)}</td>
                            <td className={styles.td_countReport}>মোট = {EnglishToBanglaNumber(`${unionData?.totals?.totalApproved}`)}</td>

                        </tr>
                    </tbody>
                </table>
                <img
                    style={{
                        content: "''",
                        display: 'block',
                        width: '500px',
                        height: '500px',
                        backgroundImage: `url(${unionData?.userData?.pageLogo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        position: 'absolute',
                        top: '100px',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: 'auto',
                        zIndex: '22',
                        opacity: '0.1',
                    }}


                    src={unionData?.userData?.pageLogo} />
            </div>
        </div>

    )
}
export default CertificateCountReport;