const RelationshipSelect = [
    {
        "id": 1,
        "name": "স্বামী",
        "value": "husband"
    },
    {
        "id": 2,
        "name": "স্ত্রী",
        "value": "wife"
    },
    {
        "id": 3,
        "name": "পুত্র",
        "value": "son"
    },
    {
        "id": 4,
        "name": "কন্যা",
        "value": "daughter"
    },
    {
        "id": 5,
        "name": "মাতা",
        "value": "mother"
    },
    {
        "id": 6,
        "name": "দাদা",
        "value": "grandfather"
    },
    {
        "id": 7,
        "name": "বাবা",
        "value": "father"
    },
    {
        "id": 8,
        "name": "দাদী",
        "value": "grandmother"
    },
    {
        "id": 9,
        "name": "নানি",
        "value": "grandmother"
    },
    {
        "id": 10,
        "name": "মৃত-পুত্র",
        "value": "dead-son"
    },
    {
        "id": 11,
        "name": "মৃত-কন্যা",
        "value": "dead-daughter"
    },
    {
        "id": 12,
        "name": "মৃত-দাদা",
        "value": "dead-grandfather"
    },
    {
        "id": 13,
        "name": "মৃত-নানি",
        "value": "dead-grandfather"
    },
    {
        "id": 14,
        "name": "ভাই",
        "value": "brother"
    },
    {
        "id": 15,
        "name": "বোন",
        "value": "sister"
    },
    {
        "id": 16,
        "name": "ভাতিজা",
        "value": "nephew"
    },
    {
        "id": 17,
        "name": "নাতি",
        "value": "grandson"
    },
    {
        "id": 18,
        "name": "নাতনির",
        "value": "granddaughter"
    },
    {
        "id": 19,
        "name": "নিজ",
        "value": "self"
    },
    {
        "id": 20,
        "name": "সহোদর ভাই",
        "value": "sahōdara-brother"
    }
]






export default RelationshipSelect;
