import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateCertificateMutation } from "../../../../app/services/certificate";
import toast from "react-hot-toast";
import { CreateDeathSchema } from "../../../../helpers/validation/AllSonodCertificateSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../button/Button";
const DeathSonodEdit = ({ certificate }) => {
    const navigate = useNavigate()
    const [deathInfo, setDeathInfo] = useState(certificate?.death_information[0])
    const [updateCertificate, { isLoading, isError, isSuccess, error }] = useUpdateCertificateMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(CreateDeathSchema)});


    const UpdateOnSubmit = async (certificateEditData) => {
        const certificateDataInfo = {
            id: certificate?._id,
            data: { death_information: [certificateEditData] }
        };
        await updateCertificate(certificateDataInfo);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('আপডেট করা হয়েছে')
            reset()
            navigate('/application-certificate/death')
        }
        if (error) {
            toast.error('আপডেট করা নেই')
        }

    }, [error, isError, isSuccess]);



    return (
        <>
            <div>
                <div className="col-lg-12">
                    <h3 className="my-3">মৃত্যু সনদ {certificate?.citizen_id?.name_bangla}</h3>
                    <form onSubmit={handleSubmit(UpdateOnSubmit)}>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-3">
                                        <div className="col-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির নাম (বাংলায়){" "}
                                                <span className="from_required">*</span>{" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম বাংলা"
                                                defaultValue={deathInfo?.death_person_bn}
                                                {...register("death_person_bn")}
                                            />
                                            {errors?.death_person_bn && (
                                                <span className="form__error">
                                                    {errors?.death_person_bn.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির নাম (ইংরেজিতে){" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম ইংরেজিতে"
                                                defaultValue={deathInfo?.death_person_en}
                                                {...register("death_person_en")}
                                            />
                                            {errors?.death_person_en && (
                                                <span className="form__error">
                                                    {errors?.death_person_en.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির পিতা / স্বামীর নাম (বাংলায়){" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="পিতা / স্বামীর নাম বাংলা"
                                                defaultValue={deathInfo?.death_father_husband_bn}
                                                {...register("death_father_husband_bn")}
                                            />
                                            {errors?.death_father_husband_bn && (
                                                <span className="form__error">
                                                    {errors?.death_father_husband_bn.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির পিতা / স্বামীর নাম (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                                                defaultValue={deathInfo?.death_father_husband_en}
                                                {...register("death_father_husband_en")}
                                            />
                                            {errors?.death_father_husband_en && (
                                                <span className="form__error">
                                                    {errors?.death_father_husband_en.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির মাতার নাম (বাংলা){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="মাতার নাম বাংলা"
                                                defaultValue={deathInfo?.date_mother_bn}
                                                {...register("date_mother_bn")}
                                            />

                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত ব্যক্তির মাতার নাম (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="মাতার নাম ইংরেজিতে"
                                                defaultValue={deathInfo?.date_mother_en}
                                                {...register("date_mother_en")}
                                            />

                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত্যুর তারিখ (বাংলা){" "}
                                                <span className="from_required">*</span>
                                            </label>

                                            <input
                                                className="form-control"
                                                type="date"
                                                placeholder="জন্ম তারিখ"
                                                defaultValue={deathInfo?.date_of_birth}
                                                {...register("date_of_birth")}
                                            />
                                            {errors?.date_of_birth && (
                                                <span className="form__error">
                                                    {errors?.date_of_birth.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">
                                                মৃত্যু রেজিস্টারের নিবন্ধন নাম্বার{" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="মাতার নাম ইংরেজিতে"
                                                defaultValue={deathInfo?.death_register_no}
                                                {...register("death_register_no")}
                                            />
                                            {errors?.death_register_no && (
                                                <span className="form__error">
                                                    {errors?.death_register_no.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            btnClass='w-100'
                            isLoading={isLoading}
                            text=" জমা করুন"
                        />
                    </form>
                </div>
            </div>


        </>
    )
}
export default DeathSonodEdit;