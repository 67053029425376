import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import styles from "./BnmunicipalityTradeliense.module.css";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import FiscalYear from "../../../../utils/DateDisplay/FiscalYear";
import EnglishToBanglaNumber from "../../../../utils/EnglishToBanglaNumber";
import { RefetchData } from "../../../../components/elements/RefetchData";
import DateDisplay from "../../../../utils/DateDisplay/DateDisplay";
import FiscalSingleYearmunicipality from "../../../../utils/DateDisplay/FiscalSingleYearmunicipality";
import { CertificateFooterBn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
const BnmunicipalityTradeliense = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const componentRef = useRef();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
    const certificate = data?.certificate;
    useEffect(() => {
        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch]);

   

    return (
        <DashboardLayout>
            {
                isLoading ? <GetSpinner /> : <>
                    <div>
                        <div className="download_pdf d-flex justify-content-end">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="btn btn-md justify-content-center">
                                        প্রিন্ট করুন 
                                    </button>
                                )}
                                content={() => componentRef.current}
                            />
                        </div>
                        <div className={styles.body} ref={componentRef}>
                            <div className={styles.container}>
                                <div className={styles.content_position}>
                                    <div>
                                        <header className={styles.pdfHeader}>
                                            <div className={styles.userImageDiv}>
                                                {
                                                    certificate?.citizen_id?.profilePicture ? <div>
                                                        <img
                                                            className={styles.userImage}
                                                            src={certificate?.citizen_id?.profilePicture}
                                                            alt="sonod_images"
                                                        />
                                                    </div>
                                                        : <div className={styles.userImage_feram} >
                                                        </div>
                                                }


                                            </div>

                                            <div className={styles.title}>
                                                <img src={certificate?.headLogo} alt="sonod_images" className={styles.gotLogo} />
                                                <h2>
                                                    {
                                                        certificate?.union_or_municipality
                                                            ?.municipality_union_bn_name
                                                    }{" "}
                                                </h2>
                                                <h4>
                                                    {certificate?.union_or_municipality?.upazila?.bn_name},{" "}
                                                    {certificate?.union_or_municipality?.district?.bn_name}{" "}
                                                </h4>
                                                <h2>ট্রেড লাইসেন্স </h2>
                                                <h6 className="fs-6">লাইসেন্স নং - {EnglishToBanglaNumber(`${certificate?.sonodId}`)}</h6>
                                                <h6 className="fs-6">লাইসেন্স আইডি - {EnglishToBanglaNumber(`${certificate?.trade_license_infomation[0]?.license_id}`)}</h6>

                                            </div>
                                            <div className={styles.blankDiv}></div>
                                        </header>
                                        <section>
                                            <div className={styles.timeline}>
                                                <h6></h6>
                                                <h2>
                                                    <span>অর্থবছর: <FiscalYear type='bangla' /></span>{" "}
                                                </h2>
                                                <h6 style={{ textAlign: "end" }}>
                                                    {" "}
                                                    <div className="d-flex justify-content-end gap-1">
                                                        <h4>ইস্যুর তাং</h4>
                                                        <h4>
                                                            <DateDisplay lang="bangla" />
                                                        </h4>
                                                    </div>
                                                </h6>
                                            </div>
                                            <div>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontWeight: 500,
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    [ পৌরসভা আদর্শ কর তফসিল ২০১৪ মোতাবেক ]
                                                </p>
                                            </div>
                                            <div className={styles.main}>
                                                <div className={styles.userInfo}>
                                                    <>
                                                        <h6>ব্যবসা প্রতিষ্ঠানের নাম </h6>
                                                        <p
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            :{" "}
                                                            {
                                                                certificate?.trade_license_infomation[0]
                                                                    ?.institution_bn_name
                                                            }
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>ব্যবসা প্রতিষ্ঠানের ঠিকানা </h6>
                                                        <p>
                                                            :{" "}
                                                            {
                                                                certificate?.trade_license_infomation[0]?.institution_address_bn
                                                            }
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>সার্কেল / রাস্তা / মহল্লা </h6>
                                                        <p>
                                                            :{" "}
                                                            {
                                                                certificate?.trade_license_infomation[0]?.circle_street_mohalla_bn_name
                                                            }
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>ব্যবসা প্রতিষ্ঠানের ধরন </h6>
                                                        <p>
                                                            {" "}
                                                            :{" "}
                                                            {
                                                                certificate?.trade_license_infomation[0]
                                                                    ?.institution_bn_type
                                                            }
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6> স্বত্ত্বাধিকারীর / মালিকের নাম</h6>
                                                        <p>: {certificate?.citizen_id?.name_bangla} {certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_name_bn}</p>
                                                    </>
                                                    <>
                                                        <h6>জাতীয় পরিচয়পত্র / জন্ম নিবন্ধন নং </h6>
                                                        <p>: {EnglishToBanglaNumber(certificate?.citizen_id?.nid)}
                                                            {EnglishToBanglaNumber(certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_nid)}
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>প্রতিষ্ঠানের প্রকৃতি</h6>
                                                        <p>
                                                            :{" "}
                                                            {certificate?.trade_license_infomation[0]?.institution_nature[0]?.bn_name}
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>টিন নম্বর </h6>
                                                        <p>
                                                            :{" "}
                                                            {EnglishToBanglaNumber(
                                                                certificate?.trade_license_infomation[0]?.tin
                                                            )}
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>ফোন / মোবাইল নং </h6>
                                                        <p>
                                                            :{" "}
                                                            {EnglishToBanglaNumber(
                                                                certificate?.trade_license_infomation[0]?.mobile_number
                                                            )}
                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>পিতা / স্বামীর নাম</h6>
                                                        <p>: {certificate?.citizen_id?.father_husband_bangla}</p>
                                                    </>

                                                    <>
                                                        <h6>মাতার নাম</h6>
                                                        <p>: {certificate?.citizen_id?.mother_name_bangla}</p>
                                                    </>
                                                    <>
                                                        <h6>বর্তমান ঠিকানা </h6>
                                                        <p>
                                                            : {certificate?.citizen_id?.present_address[0]?.village_bn_name},
                                                            {" "}
                                                            {certificate?.citizen_id?.present_address[0]?.post_office_bn},
                                                            {" "}
                                                            ওয়ার্ড নং {EnglishToBanglaNumber(
                                                                certificate?.citizen_id?.present_address[0]?.ward_no
                                                            )}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.present_address[0]?.union?.bn_name}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.present_address[0]?.upazila?.bn_name}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.present_address[0]?.district?.bn_name}


                                                        </p>
                                                    </>
                                                    <>
                                                        <h6>স্থায়ী ঠিকানা</h6>
                                                        <p>
                                                            :{" "}
                                                            {certificate?.citizen_id?.permanent_address[0]?.village_bn_name},
                                                            {" "}
                                                            {certificate?.citizen_id?.permanent_address[0]?.post_office_bn}
                                                            ,{" "} ওয়ার্ড নং {" "} {EnglishToBanglaNumber(
                                                                certificate?.citizen_id?.permanent_address[0]?.ward_no
                                                            )}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.permanent_address[0]?.union?.bn_name}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}
                                                            ,{" "}
                                                            {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}
                                                        </p>
                                                    </>
                                                </div>
                                                <div>
                                                    <h6 style={{ fontWeight: "bold" }}>আর্থিক বিবরণ  </h6>

                                                    <div className={styles.infoTable}>
                                                        <div className={styles.grid_container}>
                                                            <h6 className={styles.span_2}>ট্রেড লাইসেন্স/নবায়ন ফি</h6>
                                                            <h6 >সাইনবোর্ড/বিজ্ঞাপন কর</h6>
                                                            <h6>বিবিধ </h6>
                                                            <h6>বকেয়া </h6>
                                                            <h6>সারচার্জ/জরিমানা</h6>
                                                            <h6>উৎসকর/আয়কর</h6>
                                                            <h6>ভ্যাট বাবদ (১৫%)</h6>
                                                            <h6>মোট</h6>
                                                        </div>

                                                        {certificate?.trade_license_infomation?.map(
                                                            (trade_license_info) => (
                                                                <>
                                                                    <div key={data._id} className={styles.grid_container}>
                                                                        <p className={styles.span_2}>
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.renewal_fee
                                                                            )}{" "}
                                                                        </p>
                                                                        <p>
                                                                            {" "}
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]?.signboard
                                                                            )}{" "}
                                                                        </p>
                                                                        <p >
                                                                            {" "}
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.misc
                                                                            )}{" "}
                                                                        </p>
                                                                        <p >
                                                                            {" "}
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.due
                                                                            )}{" "}
                                                                        </p>

                                                                        <p>
                                                                            {" "}
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.service_charge
                                                                            )}{" "}
                                                                        </p>
                                                                        <p>
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.about_income_tax
                                                                            )}{" "}
                                                                        </p>
                                                                        <p>
                                                                            {" "}
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.vat
                                                                            )}{" "}
                                                                        </p>
                                                                        <p>
                                                                            {EnglishToBanglaNumber(
                                                                                trade_license_info?.license_fee_infomation[0]
                                                                                    ?.totalAmmount
                                                                            )}{" "}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: '10px' }}>
                                                            অত্র লাইসেন্স এ উল্লেখিত ব্যবসা / পেশা "ভোক্তা অধিকার সংরক্ষণ আইন- ২০০৯" যথাযথভাবে অনুসরণ করতে হবে এবং অত্র লাইসেন্স কোন অবস্থাতেই অন্য কোন ব্যক্তি বা প্রতিষ্ঠানের নিকট হস্তান্তর যোগ্য নয়।
                                                        </p>
                                                        <br />
                                                        <h4 style={{
                                                            textAlign: 'center',
                                                            color: '#de5d49'
                                                        }}>
                                                            অত্র ট্রেড লাইসেন্স এর মেয়াদ {FiscalSingleYearmunicipality('bangla', 0)} ইং সনের ৩০ জুন পর্যন্ত বলবৎ থাকিবে।
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <CertificateFooterBn certificate={certificate} accountType={userInfo?.account}
                                            sigbature={true}
                                            sigbatureConecnt={userInfo?.account === 'municipality' ? "পৌর নির্বাহী কর্মকর্তা" : "সচিবের স্বাক্ষর"}
                                        />
                                </div>
                                <CertificatePagesLogo
                                    certificate={certificate}
                                    toValue={'100px'}
                                />
                            </div>

                        </div>
                    </div>
                </>
            }

        </DashboardLayout>
    );
};
function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnmunicipalityTradeliense)

