import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import { RefetchData } from "../../../../components/elements/RefetchData";
import styles from './Bnmunicipalityother.module.css';
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import ReactToPrint from "react-to-print";
import { CertificateFooterBn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
import { connect } from "react-redux";
import { CertificateHeaderBn } from "../../../../components/elements/applicationCertificate/CertificateHeader/CertificateHeader";



const Bnmunicipalityother = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <>
          <div>
            <div className="download_pdf d-flex justify-content-end">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-md justify-content-center">
                    প্রিন্ট করুন
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>

            <div className={styles.body} ref={componentRef}>
              <div className={styles.container}>
                <div className={styles.content_position}>
                  <div>
                    <CertificateHeaderBn certificate={certificate} sonodName={certificate?.other_info?.title_bn} />
                    <div dangerouslySetInnerHTML={{ __html: certificate?.other_info?.detail_bn}} />
                  </div>
                  <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={true} />
                </div>

                <CertificatePagesLogo
                  certificate={certificate}
                  toValue={'200px'}
                />
              </div>
            </div>
          </div>

        </>
      }
    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Bnmunicipalityother)