
import React, { } from 'react';
import { useReactToPrint } from 'react-to-print'
import EnglishToBanglaNumber from '../../../utils/EnglishToBanglaNumber';
import SonodTypeEnToBn from '../../../utils/SonodTypeEnToBn';

const MunicipalityVerifySonodTable = ({ certificate }) => {

    const createdAt = new Date(certificate?.createdAt).toISOString().split('T')[0];


    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <div >
            <div ref={componentRef}>
                {/* Content */}
                <div   >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p className="pragraph6">
                        ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেম।{" "}
                    </p>

                    <p style={{ textIndent: "0pt", textAlign: "left" }} />
                    <p style={{ textIndent: "0pt", textAlign: "left" }} />
                    <p style={{ textIndent: "0pt", textAlign: "left" }} />
                    <p className="pragraph7"> অনলাইন যাচাই কপি{" "}/ Online Verify Copy</p>

                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </div>

                {/* table  */}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <table
                        style={{ borderCollapse: "collapse", marginLeft: "5.274pt" }}
                        cellSpacing="0"
                    >
                        {/* Table Row 1 */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph1">সনদ নং:</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {
                                        EnglishToBanglaNumber(`${certificate?.sonodId}`)
                                    }
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        lineHeight: "15pt",
                                        textAlign: "left",
                                    }}
                                >
                                    Certificate No
                                </p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.sonodId}
                                    <br />
                                </p>
                            </td>
                        </tr>

                        {/* Table row 2 */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph2">ইস্যুর তারিখ</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {EnglishToBanglaNumber(createdAt)}
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph3">Date of Issue</p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {createdAt}
                                    <br />
                                </p>
                            </td>
                        </tr>

                        {/* Table row 3 */}
                        <tr style={{ height: "26pt" }}>
                            <td className="tableData1" >
                                <p className="pragraph3">সনদের ধরণ</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {SonodTypeEnToBn(`${certificate?.sonod_type}`)}
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph4">Type of certificate</p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.sonod_type}
                                    <br />
                                </p>
                            </td>
                        </tr>
                        {
                            certificate?.sonod_type == 'tradelicense' && <tr style={{ height: "26pt" }}>
                                <td className="tableData1" >
                                    <p className="pragraph3">ব্যবসা প্রতিষ্ঠানের নাম</p>
                                </td>
                                <td className="tableData2">
                                    <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                        {certificate?.trade_license_infomation[0]?.institution_bn_name}
                                        <br />
                                    </p>
                                </td>
                                <td className="tableData3">
                                    <p className="pragraph4">Business Name</p>
                                </td>
                                <td className="tableData4">
                                    <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                        {certificate?.trade_license_infomation[0]?.institution_en_name}
                                        <br />
                                    </p>
                                </td>
                            </tr>

                        }
                        {/* table row 5  */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph3">নাম</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.name_bangla}
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph4">Name:</p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.name_english}
                                    <br />
                                </p>
                            </td>
                        </tr>

                        {/* table row 6 */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph3">পিতা/স্বামীর নাম</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.father_husband_bangla}
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph2">
                                    <span className="s6" style={{ backgroundColor: "#f8f8f9" }}>
                                        Father's/Husband
                                    </span>
                                    <span className="s7"> </span>
                                    <span className="s6" style={{ backgroundColor: "#f8f8f9" }}>
                                        Name:
                                    </span>
                                </p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.father_husband_english}
                                    <br />
                                </p>
                            </td>
                        </tr>

                        {/* table row 7 */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph3">মায়ের নাম</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.mother_name_bangla}
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph3">
                                    <span className="s6" style={{ backgroundColor: "#f8f8f9" }}>
                                        Mother's Name:
                                    </span>
                                </p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {certificate?.citizen_id?.mother_name_english}
                                    <br />
                                </p>
                            </td>
                        </tr>

                        {/* table row 8 */}
                        <tr style={{ height: "76pt" }}>
                            <td className="tableData1">
                                <p className="pragraph3">স্থায়ী ঠিকানা</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    ইউনিয়নঃ {
                                        certificate?.citizen_id?.permanent_address[0].post_office_bn
                                    } , ওয়ার্ড নংঃ  {EnglishToBanglaNumber(certificate?.citizen_id?.permanent_address[0].ward_no)}, উপজেলাঃ {certificate?.citizen_id?.permanent_address[0].upazila.bn_name
                                    },
                                    জেলাঃ {certificate?.citizen_id?.permanent_address[0].district.bn_name
                                    },  বিভাগঃ {certificate?.citizen_id?.permanent_address[0].division?.bn_name
                                    }
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p className="pragraph4">Permanent Address</p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    Union: {
                                        certificate?.citizen_id?.permanent_address[0]?.union?.name
                                    } , Word no: {certificate?.citizen_id?.permanent_address[0].ward_no}, Upozilla: {certificate?.citizen_id?.permanent_address[0].upazila?.name
                                    }, Zilla: {certificate?.citizen_id?.permanent_address[0].district?.name
                                    }, Division: {certificate?.citizen_id?.permanent_address[0].division?.name}
                                    <br />

                                </p>
                            </td>
                        </tr>

                        {/* table row 9 */}
                        <tr style={{ height: "32pt" }}>
                            <td className="tableData1">
                                <p className="pragraph5">সনদ ইস্যুকরণ অফিস:</p>
                            </td>
                            <td className="tableData2">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {
                                        certificate?.municipality_bn_name
                                    }
                                    <br />
                                </p>
                            </td>
                            <td className="tableData3">
                                <p
                                    style={{
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left",
                                    }}
                                >
                                    Certificate Issuing Office:
                                </p>
                            </td>
                            <td className="tableData4">
                                <p style={{ textIndent: "0pt", textAlign: "left", paddingLeft: '10px' }}>
                                    {
                                        certificate?.municipality_en_name
                                    }
                                    <br />
                                </p>
                            </td>
                        </tr>

                    </table>



                </div>

            </div>


            <div onClick={handlePrint} className='container' style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <div className='col-lg-1 text-center'
                    style={{
                        backgroundColor: "#29A56C",
                        cursor: 'pointer',
                        borderRadius: '3px',
                        color: "white",
                        padding: '10px',
                        fontSize: '20px'
                    }}

                >
                    প্রিন্ট
                </div>
            </div>
        </div>
    );
};

export default MunicipalityVerifySonodTable;