import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useUpdatemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";
import RelationshipSelect from "../../../../helpers/shared/RelationshipSelect";
const EditFamilyCertificatemunicipality = ({ certificate }) => {
    const navigate = useNavigate()
    const [familyDetails, setFamilyDetails] = useState(certificate?.family_information)
    const [updateCertificate, { isLoading, isError, isSuccess, error }] = useUpdatemunicipalitycertificateMutation()
    const [familyDtError, setFamilyDtError] = useState(null)
    const memberInitialData = {
        memberNameBn: "",
        memberNameEn: "",
        memberDate: "",
        memberNidDeath: "",
        memberRelation: ""
    }
    const [memberData, setMemberData] = useState(memberInitialData)






    // Function to handle input changes
    const addMemberEditInputData = (index, field, value) => {
        setFamilyDetails((prevFamilyDetails) =>
            prevFamilyDetails.map((member, i) =>
                i === index ? { ...member, [field]: value } : member
            )
        );
    };
    const handleInputChange = (e) => {
        setFamilyDtError(null)
        const { name, value } = e.target;
        setMemberData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleAddFamilyDetails = () => {
        const { memberNameBn, memberRelation } = memberData;
        if (!memberNameBn || !memberRelation) {
            setFamilyDtError("সম্পূর্ণ তথ্য দিতে হবে");
        } else {
            const updatedFamilyDetails = [...familyDetails, memberData];
            setFamilyDetails(updatedFamilyDetails);
            setMemberData(memberInitialData);

        }
    };
    const handleDeleteFamilyMember = (index) => {
        let updatedFamilyDetails = [...familyDetails];
        updatedFamilyDetails.splice(index, 1);
        setFamilyDetails(updatedFamilyDetails);
    };

    const familyupdateOnSubmit = async () => {
        const certificateDataInfo = {
            id: certificate?._id,
            data: { family_information: familyDetails }
        };
        await updateCertificate(certificateDataInfo);

    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('আপডেট করা হয়েছে')
            navigate('/application-certificate/municipality/family')
        }
        if (error) {
            toast.error('আপডেট করা নেই')
        }

    }, [error, isError, isSuccess]);
    return (
        <>
            <div>
                <div className="col-lg-12">
                    <h3>{certificate?.citizen_id?.name_bangla}</h3>
                    <div className="card mb-4">
                        <div className="card-body">
                            {/* ========== fimily certificate ==========  */}
                            <div className='d-flex justify-content-between align-items-center mb-3' >
                                <h5> পরিবারের সদস্যদের তথ্য :-</h5>

                                <button onClick={handleAddFamilyDetails} type='button' className=' btn-sm btn-primary '>
                                    যোগ করুন +
                                </button>
                            </div>
                            <div>
                                <div className="row gx-3 mb-2" style={{
                                    border: '1px solid #3bb77e',
                                    padding: '2px',
                                    borderRadius: '6px'
                                }}>
                                    <div className="col-3 mb-3">
                                        <label className="form-label">
                                            নাম: (বাংলা){' '}
                                            <span className="from_required">*</span>{' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name='memberNameBn'
                                            placeholder="নাম বাংলা"
                                            value={memberData.memberNameBn}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <div className="col-3 mb-3">
                                        <label className="form-label">
                                            নাম: (ইংরেজিতে){' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name='memberNameEn'
                                            placeholder="নাম ইংরেজিতে"
                                            value={memberData.memberNameEn}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-2 mb-3">
                                        <label className="form-label">
                                            এনআইডি / জন্ম নিবন্ধন {' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name='memberNidDeath'
                                            placeholder=" এনআইডি বা  জন্ম নিবন্ধন "
                                            value={memberData.memberNidDeath}
                                            onChange={handleInputChange}
                                        />

                                    </div>

                                    <div className="col-2 mb-3">
                                        <label className="form-label">
                                            তারিখ {' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name='memberDate'
                                            value={memberData.memberDate}
                                            onChange={handleInputChange}
                                        />

                                    </div>

                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="Promo_amount" className="form-label">
                                            সম্পর্ক <span className="from_required">*</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="memberRelation"
                                            value={memberData.memberRelation}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">
                                                সম্পর্ক নির্বাচন করুন
                                            </option>
                                            {RelationshipSelect?.map((relationship, index) => <option
                                                key={index}
                                                value={relationship?.value}>{relationship?.name}</option>)}


                                        </select>
                                    </div>
                                </div>
                                <p style={{ color: "red" }}>{familyDtError}</p>

                                <div className=' mt-4' style={{ paddingTop: "25px" }}>
                                    {
                                        familyDetails.map((details, index) => (
                                            <div className="row gx-3 mb-4 shadow-sm" key={index}>
                                                <div className="col-3 mb-3">
                                                    <label className="form-label">
                                                        নাম: (বাংলা){' '}
                                                        <span className="from_required">*</span>{' '}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name='memberNameBn'
                                                        value={details?.memberNameBn || ''}
                                                        onChange={(e) => addMemberEditInputData(index, 'memberNameBn', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-2 mb-3">
                                                    <label className="form-label">
                                                        নাম: (ইংরেজিতে){' '}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name='memberNameEn'
                                                        value={details?.memberNameEn || ''}
                                                        onChange={(e) => addMemberEditInputData(index, 'memberNameEn', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-2 mb-3">
                                                    <label className="form-label">
                                                        এনআইডি / জন্ম নিবন্ধন {' '}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name='memberNidDeath'
                                                        value={details?.memberNidDeath || ''}
                                                        onChange={(e) => addMemberEditInputData(index, 'memberNidDeath', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-2 mb-3">
                                                    <label className="form-label">
                                                        তারিখ
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        name='memberDate'
                                                        type="date"
                                                        value={details?.memberDate || ''}
                                                        onChange={(e) => addMemberEditInputData(index, 'memberDate', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-2 mb-3">
                                                    <label className="form-label">
                                                        সম্পর্ক
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="memberRelation"
                                                        onChange={(e) => addMemberEditInputData(index, 'memberRelation', e.target.value)}
                                                    >
                                                        {RelationshipSelect?.map((relationship, index) => <option
                                                            key={index}
                                                            value={relationship?.value}
                                                            selected={relationship?.value == details?.memberRelation}
                                                        >
                                                            {relationship?.name}
                                                        </option>)}

                                                    </select>

                                                </div>
                                                <div className=' col-1 d-flex align-items-center justify-content-end '>
                                                    <button type='button' onClick={() => handleDeleteFamilyMember(index)} className='btn btn-danger '>Delete</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <button
                                    style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                                    onClick={() => familyupdateOnSubmit()}
                                    className="btn btn-md rounded font-sm "
                                >
                                    সংশোধন করুন
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default EditFamilyCertificatemunicipality;