import ReactToPrint from 'react-to-print';
import styles from './EnParibarikSonod.module.css';
import { useRef } from 'react';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { CertificateHeaderEn } from '../../CertificateHeader/CertificateHeader';
import { CertificateFooterEn } from '../../CertificateFooter/CertificateFooter';
import { connect } from 'react-redux';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import { RefetchData } from '../../../RefetchData';
import { useEffect } from 'react';
import { useState } from 'react';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';
import GenderHandel from '../../../../../utils/GenderHandel';

const EnParibarikSonod = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end ">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}  >
              <div className={styles.content_position}>
                <CertificateHeaderEn certificate={certificate} sonodName={'Family Certificate'} />
                <div className={styles.main}>
                  <p className={styles.mainText}>
                    This is to Certify that,<b> {GenderHandel(certificate.gender)} {certificate?.citizen_id ?.name_english}</b>,
                    Father/Husband:{' '}
                    <b>{certificate?.citizen_id?.father_husband_english}</b> of Address Village:{' '}{certificate?.citizen_id?.permanent_address[0]?.village_en_name}, Holding No:{' '}
                    {certificate?.citizen_id?.permanent_address[0]?.holding_no}, Post Office:
                    {certificate?.citizen_id?.permanent_address[0]?.post_office_en}, Ward No: {certificate?.citizen_id?.permanent_address[0]?.ward_no},{' '}
                    {certificate?.union?.union_en_name}
                    Thana / Upazilla: {certificate?.citizen_id?.permanent_address[0]?.upazila?.name}, District:
                    {certificate?.citizen_id?.permanent_address[0]?.district?.name} is permanent resident of this
                    area. The list of members of the mentioned person is as follows:
                  </p>
                  <div className={styles.infoTable}>
                    <div className={styles.grid_container}>
                      <h5>SL </h5>
                      <h5 className={styles.span_2}>Name </h5>
                      <h5 className={styles.span_2}>Nid or Birth No</h5>
                      <h5>Date of Birth</h5>
                      <h5>Relationship</h5>
                    </div>
                    {
                      certificate?.family_information.map((familyDdata, index) => <div
                        key={data._id}
                        className={styles.grid_container}>
                        <p>{index + 1}</p>
                        <p className={styles.span_2}>{familyDdata?.memberNameEn}</p>
                        <p className={styles.span_2}>{familyDdata?.memberNidDeath}</p>
                        <p style={{textTransform:'capitalize'}}>{familyDdata?.memberDate}</p>
                        <p style={{textTransform:'capitalize'}}>{familyDdata?.memberRelation}</p>
                      </div>)
                    }
                  </div>

                  <div className={styles.mainText}>
                    <p>
                      The certificate is provided based on the information provided
                      by claimant. Thus, the claimant shall be solely responsible
                      any misinformation or misrepresentation of any family members
                      and the approver shall not be liable for any misinformation
                      and the certificate shall be considered as abandoned.
                    </p>
                    <br />
                    <p>I wish all the heirs well.</p>
                  </div>
                </div>
                <CertificateFooterEn certificate={certificate} accountType={accountType} marginHandel={false}
                  sigbature={true}
                  sigbatureConecnt="Identifier"
                />
              </div>

              <CertificatePagesLogo
                certificate={certificate}
                toValue={'100px'}
              />
            </div>
          </div>

        </div>
      }
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(EnParibarikSonod)
