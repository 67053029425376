import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateDeathSchema } from "../../../../helpers/validation/AllSonodCertificateSchema";
import { useGetSingleCitizenMutation } from "../../../../app/services/citizen";
import FindcitizensSearch from "../../../findcitizens/FindcitizensSearch";
import Button from "../../../button/Button";
import { useCreatemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";

function CreateDeathCertificatemunicipalityModal({ userInfo, contects, modal, setOpen, addisLoading }) {
    const { type } = modal;
    const [userData, setUserData] = useState(false);
    const [nidOrNumber, setNidOrNumber] = useState("");

    const [getCitizen, { data, isLoading, isSuccess, isError, error }] =
        useGetSingleCitizenMutation();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(CreateDeathSchema) });

    const handleSearchCitizen = async () => {
        if (nidOrNumber) {
            const searchData = { nidOrNumber };
            await getCitizen(searchData);
        }
    };
    const [
        createCertificate,
        { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err },
    ] = useCreatemunicipalitycertificateMutation();

    const createOnSubmit = async (certificateData) => {
        if (!data) {
            toast.error("আপনার নাগরিক কে খুজে বের করুন");
        } else {
            const user_infoData = { ...data?.data };
            if (
                user_infoData &&
                user_infoData._id &&
                Object.getOwnPropertyDescriptor(user_infoData, "_id").configurable
            ) {
                delete user_infoData._id;
            } else {
            }
            const certificateDataInfo = {
                ...user_infoData,
                status: userInfo?.role == 'secretary' ? 'approved' : 'pending',
                citizen_id: userData?._id,
                death_information: [certificateData],
                sonod_type: "death",
            };

            await createCertificate(certificateDataInfo);
        }
    };

    useEffect(() => {
        if (isSucc) {
            setOpen({ type: false }, reset())
            toast.success('সঠিকভাবে  এড করা হয়েছ');
        }
        if (isErr) {
            toast.error("errror", { id: "create" });
        }
    }, [isSucc, isErr, err]);

    return (
        <>
            {type && (
                <div className="modal_product">
                    <section className="content-mainDash">
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header">
                                    <h2 className="content-title">{contects?.title}</h2>
                                    <button onClick={() => setOpen({ type: false }, reset())}>
                                        X
                                    </button>
                                </div>
                                <FindcitizensSearch
                                    onChangeGetValue={setNidOrNumber}
                                    onClickSubmitButton={handleSearchCitizen}
                                    isLoading={isLoading}
                                    setInputFildData={setUserData}
                                    data={data?.data}
                                />
                            </div>
                            <form onSubmit={handleSubmit(createOnSubmit)}>
                                <div className="col-lg-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row gx-3">
                                                <div className="col-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির নাম (বাংলায়){" "}
                                                        <span className="from_required">*</span>{" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="নাম বাংলা"
                                                        {...register("death_person_bn")}
                                                    />
                                                    {errors?.death_person_bn && (
                                                        <span className="form__error">
                                                            {errors?.death_person_bn.message}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির নাম (ইংরেজিতে){" "}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="নাম ইংরেজিতে"
                                                        {...register("death_person_en")}
                                                    />
                                                    {errors?.death_person_en && (
                                                        <span className="form__error">
                                                            {errors?.death_person_en.message}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির পিতা / স্বামীর নাম (বাংলায়){" "}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="পিতা / স্বামীর নাম বাংলা"
                                                        {...register("death_father_husband_bn")}
                                                    />
                                                    {errors?.death_father_husband_bn && (
                                                        <span className="form__error">
                                                            {errors?.death_father_husband_bn.message}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির পিতা / স্বামীর নাম (ইংরেজিতে){" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                                                        {...register("death_father_husband_en")}
                                                    />
                                                    {errors?.death_father_husband_en && (
                                                        <span className="form__error">
                                                            {errors?.death_father_husband_en.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির মাতার নাম (বাংলা){" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="মাতার নাম বাংলা"
                                                        {...register("date_mother_bn")}
                                                    />

                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত ব্যক্তির মাতার নাম (ইংরেজিতে){" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="মাতার নাম ইংরেজিতে"
                                                        {...register("date_mother_en")}
                                                    />

                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত্যুর তারিখ (বাংলা){" "}
                                                        <span className="from_required">*</span>
                                                    </label>

                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="জন্ম তারিখ"
                                                        {...register("date_of_birth")}
                                                    />
                                                    {errors?.date_of_birth && (
                                                        <span className="form__error">
                                                            {errors?.date_of_birth.message}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মৃত্যু রেজিস্টারের নিবন্ধন নাম্বার{" "}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="মাতার নাম ইংরেজিতে"
                                                        {...register("death_register_no")}
                                                    />
                                                    {errors?.death_register_no && (
                                                        <span className="form__error">
                                                            {errors?.death_register_no.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    isLoading={addisLoading}
                                    text="জমা করুন"
                                />
                             
                            </form>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}

function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users };
}

export default connect(mapStateToProps, null)(CreateDeathCertificatemunicipalityModal);
