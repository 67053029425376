import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { usePutSingleUserMutation } from "../../../../app/services/authUser";

export default function EditSecretaryModal({ modal, setModal }) {
  const { register,handleSubmit,reset,formState: { errors }} = useForm();
  const { type, data } = modal;
  const [updateUser, { isSuccess, isLoading }] = usePutSingleUserMutation();

  const onSubmit = async (data) => {
    const updatedData = { data, id: modal?.data?._id };
    await updateUser(updatedData);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("তথ্য আপডেট সফল হয়েছে");
      reset();
      setModal({ type: false });
    }
  }, [isSuccess]);
  return (
    <>
      {type && (
        <div className="modal_product row">
          <section className="content-main col-10">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">তথ্য আপডেট</h2>
                  <button onClick={() => setModal({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="">
                        <h3 className="mb-1"> সচিবের তথ্য</h3>
                        <div className="row gx-2">
                          <div className="col-3 ">
                            <label className="form-label">নাম (বাংলায়) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First name"
                              {...register("secretaryBanglaName")}
                              defaultValue={data?.secretaryBanglaName}
                            />
                          </div>

                          <div className="col-3 ">
                            <label className="form-label">
                              {" "}
                              নাম (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First name"
                              {...register("secretaryEnglishName")}
                              defaultValue={data?.secretaryEnglishName}
                            />
                          </div>

                          <div className="col-3 mb-3">
                            <label className="form-label">ইমেইল</label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="example@mail.com"
                              defaultValue={data?.secretaryEmail}
                              disabled
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">মোবাইল নম্বর</label>
                            <input
                              className="form-control"
                              type="String"
                              placeholder="+017XXXXXXXX"
                              {...register("secretaryMobileNumber")}
                              defaultValue={data?.secretaryMobileNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <h3 className="mb-1">উদ্যোক্তার তথ্য</h3>
                        <div className="row gx-2">
                          <div className="col-3 ">
                            <label className="form-label">নাম (বাংলায়) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First name"
                              {...register("entrepreneurBanglaName")}
                              defaultValue={data?.entrepreneurBanglaName}
                            />
                          </div>

                          <div className="col-3 ">
                            <label className="form-label">
                              {" "}
                              নাম (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First name"
                              {...register("entrepreneurEnglishName")}
                              defaultValue={data?.entrepreneurEnglishName}
                            />
                          </div>

                          <div className="col-3 mb-3">
                            <label className="form-label">ইমেইল</label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="example@mail.com"
                              defaultValue={data?.entrepreneurEmail}
                              disabled
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">মোবাইল নম্বর</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="+017XXXXXXXX"
                              {...register("entrepreneurMobileNumber")}

                              defaultValue={data?.entrepreneurMobileNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <h3 className="mb-1">চেয়ারম্যান তথ্য</h3>
                        <div className="row gx-2  gy-4">
                          <div className="col-3 ">
                            <label className="form-label">নাম (বাংলায়) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First name"
                              {...register("chairmanBanglaName")}

                              defaultValue={data?.chairmanBanglaName}
                            />
                          </div>

                          <div className="col-3">
                            <label className="form-label">
                              {" "}
                              নাম (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              defaultValue={data?.chairmanEnglishName}
                              {...register("chairmanEnglishName")}
                            />
                          </div>
                          <div className="col-3 ">
                            <label className="form-label"> ইউনিয়ন নাম (বাংলায়) </label>
                            <input
                              className="form-control" 
                              type="text"
                               placeholder="ইউনিয়ন  নাম ইংরেজিতে"
                              defaultValue={data?.union_bn_name}
                              {...register("union_bn_name")}
                            />
                          </div>
                          <div className="col-3 ">
                            <label className="form-label"> ইউনিয়ন নাম (ইংরেজিতে) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="ইউনিয়ন  নাম ইংরেজিতে"
                              defaultValue={data?.union_en_name}
                              {...register("union_en_name")}
                            />
                          </div>
                          <div className="col-3 ">
                            <label className="form-label"> জনপ্রতিনিধি ধরণ (বাংলায়) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="ইউনিয়ন জনপ্রতিনিধি ধরণ বাংলায়"
                              defaultValue={data?.elected_officer_bn}
                              {...register("elected_officer_bn")}
                            />
                          </div>
                          <div className="col-3 ">
                            <label className="form-label"> জনপ্রতিনিধি ধরণ (ইংরেজিতে) </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="ইউনিয়ন জনপ্রতিনিধি ধরণ ইংরেজিতে"
                              defaultValue={data?.elected_officer_en}
                              {...register("elected_officer_en")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setModal({ type: false })}
                    className="btn btn-warning hover-up me-2 px-3 py-2 rounded font-sm  "
                  >
                    বাতিল করুন
                  </button>
                  <input
                    style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                    value={"সংরক্ষণ করুন"}
                    className="btn btn-md rounded font-sm hover-up "
                    type="Submit" 
                  />
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
