const Button = ({ text, btnClass, clickFuntion, isLoading }) => {
    return (
        <div className={btnClass} style={{ marginTop: '22px' }}>
            {
                isLoading ? <button
                    type={isLoading ? 'text' : 'submit'}
                    style={{ cursor: isLoading ? 'no-drop' : '' }}
                    className="btn btn-md rounded font-sm "
                >
                    অপেক্ষা করুন..
                </button> : <button
                    onClick={clickFuntion}
                    className="btn btn-md rounded font-sm"
                >
                    {text}
                </button>
            }

        </div>
    )
}
export default Button;