import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import { RefetchData } from "../../../../components/elements/RefetchData";
import styles from './Enmunicipalitywarish.module.css';
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import ReactToPrint from "react-to-print";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
import { connect } from "react-redux";
import { CertificateHeaderEn } from "../../../../components/elements/applicationCertificate/CertificateHeader/CertificateHeader";
import { CertificateFooterEn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";
import GenderHandel from "../../../../utils/GenderHandel";

const Enmunicipalitywarish = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}>
              <div className={styles.content_position}>
                <CertificateHeaderEn certificate={certificate} sonodName={'Inheritance certificate'} />
                <div className={styles.main}>
                  <p className={styles.mainText}>
                    It is hereby confirmed that,{" "}{GenderHandel(certificate?.gender)} name {certificate?.warish_sonod_information[0]?.living_person_name_en},{" "} Father/Husband: {certificate?.warish_sonod_information[0]?.living_person_father_husband_en}, {" "} Mother Name: {certificate?.warish_sonod_information[0]?.living_person_mother_en}. A permanent
                    resident of the area. His Village:{" "}
                    {certificate?.citizen_id?.present_address[0]?.village_en_name}, Holding:{" "}
                    {certificate?.citizen_id?.present_address[0]?.holding_no}, Post Office:{" "}
                    {certificate?.citizen_id?.present_address[0]?.post_office_en}, Ward:{" "}
                    {certificate?.citizen_id?.present_address[0]?.ward_no},{" "}
                    Municipality: {certificate?.union_or_municipality?.municipality_union_en_name},{" "}
                    Upazila: {certificate?.citizen_id?.present_address[0]?.upazila?.name},
                    District: {certificate?.citizen_id?.present_address[0]?.district?.name}.{" "}
                    was permanent resident of this area. As per documentary/witness’s evidence, he deceased on {certificate?.warish_sonod_information[0]?.living_person_date}
                    {certificate?.warish_sonod_information[0]?.living_person_nid_no && <>
                      {" "} and death register number ks   {
                        certificate?.warish_sonod_information[0]?.living_person_nid_no
                      }
                    </>}.
                    At the time of death, he left the following heirs:
                  </p>
                  <div className={styles.infoTable}>
                    <div className={styles.grid_container}>
                      <h5>Serial no </h5>
                      <h5 className={styles.span_2}>Name </h5>
                      <h5 className={styles.span_2}>Nid or Birth No</h5>
                      <h5 >Date of Birth</h5>
                      <h5>Relationship</h5>
                    </div>

                    {certificate?.warish_sonod_information[0]?.family_information.map(
                      (info, i) => (
                        <>
                          <div key={i} className={styles.grid_container}>
                            <p>{i + 1}</p>
                            <p className={styles.span_2}>{info?.memberNameEn}</p>
                            <p className={`${styles.span_2} mb-0`}>{info?.memberNidDeath}</p>
                            <p className={` mb-0`}>
                              {info?.memberDate}
                            </p>
                            <p style={{ textTransform: 'capitalize' }} className="mb-0">{info?.memberRelation}</p>
                          </div>
                        </>
                      )
                    )}
                  </div>

                  <div className={styles.mainText}>
                    <p>
                      There are no heirs other than the person mentioned. Only the
                      specified person/persons shall be considered heirs with
                      respect to the disposal of all debts, property, and
                      belongings.
                    </p>
                    <p>I wish all the heirs well.</p>
                    <p className={styles.specialNote}>
                      <b>Note :</b> The applicant will be liable if he has
                      deliberately omitted any inheritance or provided any wrong
                      information. The chairman's office will not be liable for it
                      in any way and the said certificate will be considered as null
                      and void.
                    </p>
                  </div>
                </div>
                <CertificateFooterEn certificate={certificate} accountType={userInfo?.account} marginHandel={false}
                  sigbature={true}
                  sigbatureConecnt="Identifier"
                />
              </div>

              <CertificatePagesLogo
                certificate={certificate}
                toValue={'100px'}
              />
            </div>
          </div>
        </div>
      }

    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Enmunicipalitywarish)
