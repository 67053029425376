import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import { RefetchData } from "../../../../components/elements/RefetchData";
import EditWarishCertificatemunicipality from "../../../../components/allCertificates/municipality/warish/EditWarishCertificatemunicipality";

const WarishEditCertificateMunicipalityEditPage = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);


    const certificate = useMemo(() => data?.certificate, [data, isLoading, isError, refetch]);
    useEffect(() => {

        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch, certificate]);
    let content;
    if (!certificate || isLoading || isError) {
        content = <di>অপেক্ষা করুন....</di>
    } else {
        content = <EditWarishCertificatemunicipality certificate={certificate} />
    }

    return (
        <DashboardLayout>
            {content}
        </DashboardLayout>
    )
}

function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(WarishEditCertificateMunicipalityEditPage)

