import ReactToPrint from "react-to-print";
import styles from "./BnWarishCertificate.module.css";
import { useRef } from "react";
import DashboardLayout from "../../../../layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useGetSingleCertificateQuery } from "../../../../../app/services/certificate";
import EnglishToBanglaNumber from "../../../../../utils/EnglishToBanglaNumber";
import BnFormatDate from "../../../../../utils/DateDisplay/bnFormatDate";
import { CertificateFooterBn } from "../../CertificateFooter/CertificateFooter";
import { connect } from "react-redux";
import { CertificateHeaderBn } from "../../CertificateHeader/CertificateHeader";
import { useState } from "react";
import { useEffect } from "react";
import { RefetchData } from "../../../RefetchData";
import GetSpinner from "../../../../../helpers/shared/GetSpinner";
import RelationshipBangla from "../../../../../utils/RelationshipBangla";
import CertificatePagesLogo from "../../CertificatePagesLogo/CertificatePagesLogo";
const BnWarishCertificate = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div
            className={styles.body} ref={componentRef}
          >
            <div
              className={styles.container}
            >
              <div className={styles.content_position}>
                <CertificateHeaderBn certificate={certificate} sonodName={'ওয়ারিশ সনদ'} />
                <div className={styles.main}>
                  <p className={`${styles.mainText}`}>
                    এই মর্মে প্রত্যয়ন করা যাইতেছে যে,{""} <b style={{ fontWeight: 'bold' }}>{certificate?.warish_sonod_information[0]?.living_person_name_bn}</b>,{" "}
                    পিতা/স্বামীরঃ <b style={{ fontWeight: 'bold' }}>{certificate?.warish_sonod_information[0]?.living_person_father_husband_bn}</b>,{" "} মাতাঃ <b style={{ fontWeight: 'bold' }}>{certificate?.warish_sonod_information[0]?.living_person_mother_bn}</b>।{" "} তাহার  গ্রামঃ{" "}
                    {certificate?.citizen_id?.present_address[0]?.village_bn_name},{" "}বাসা/হোল্ডিং-{" "}
                    {EnglishToBanglaNumber(
                      certificate?.citizen_id?.present_address[0]?.holding_no
                    )}, 
                     ওয়ার্ডঃ{" "}
                    {EnglishToBanglaNumber(
                      certificate?.citizen_id?.present_address[0]?.ward_no
                    )},
                    ডাকঘরঃ {certificate?.citizen_id?.present_address[0]?.post_office_bn},
                    ইউনিয়নঃ{" "}
                    {certificate?.citizen_id?.present_address[0]?.union?.bn_name},{" "}
                    উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name},
                    জেলাঃ {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}। অত্র এলাকার
                    একজন স্থায়ী বাসিন্দা  ছিলেন।  {" "}
                    প্রকৃত নথি ও  সাক্ষ্য অনুয়ায়ী, তিনি {BnFormatDate(certificate?.warish_sonod_information[0]?.living_person_date)} তারিখে  মৃত্যুবরণ করিয়াছেন

                    {certificate?.warish_sonod_information[0]?.living_person_nid_no && <>
                      {" "} মৃত্যু রেজিস্টার নং  {EnglishToBanglaNumber(
                        certificate?.warish_sonod_information[0]?.living_person_nid_no
                      )}
                    </>}।
                    মৃত্যুকালে তিনি নিম্ন বর্ণিত ওয়ারিশগণ রাখিয়া মৃত্যুবরণ করিয়াছেন।
                  </p>
                  <div className={styles.infoTable}>
                    <div className={styles.grid_container}>
                      <h5>ক্রমিক নং </h5>
                      <h5 className={styles.span_2}>নাম </h5>
                      <h5 className={styles.span_2}>এনআইডি/জন্ম নিবন্ধন</h5>
                      <h5>জন্ম তারিখ</h5>
                      <h5>সম্পর্ক</h5>
                    </div>
                    {certificate?.warish_sonod_information[0]?.family_information.map(
                      (info, i) => (
                        <>
                          <div key={i} className={styles.grid_container}>
                            <p className="mb-0">
                              {EnglishToBanglaNumber(`${i + 1}`)}
                              {". "}
                            </p>
                            <p className={`${styles.span_2} mb-0`}>
                              {info?.memberNameBn}
                            </p>
                            <p className={`${styles.span_2} mb-0`}>
                              {
                                info?.memberNidDeath ? EnglishToBanglaNumber(info?.memberNidDeath) : ""
                              }
                            </p>
                            <p >{EnglishToBanglaNumber(`${info?.memberDate}`)}
                            </p>
                            <p className="mb-0">{RelationshipBangla(info?.memberRelation)}</p>
                          </div>
                          {/* 
                         <p ></p>
                        
                        */}
                        </>
                      )
                    )}
                  </div>

                  <div className={`${styles.mainText} text-dark`}>
                    <p>
                      উল্লেখিত ব্যক্তি / ব্যক্তিগণ ছাড়া আর কোনো ওয়ারিশ নাই। সকল
                      প্রকার দেনাপাওনা / সম্পত্তি / বিষয় নিস্পত্তি করার ব্যাপারে
                      কেবলমাত্র উল্লেখিত ব্যক্তি / ব্যক্তিগণই ওয়ারিশ  হিসেবে
                      বিবেচিত হইবে।
                    </p>
                    <p>আমি সকল ওয়ারিশগণের মঙ্গল কামনা করিতেছি। </p>
                    <p className={styles.specialNote}>
                      <b> বিঃদ্রঃ </b>আবেদনকারী ‍যদি ইচ্ছাকৃতভাবে কোন ওয়ারিশ
                      বাদ দিয়ে থাকেন বা কোন ভুল তথ্য প্রদান করিয়া থাকেন তাহলে তিনি
                      দ্বায়ী থাকিবেন। তাহার জন্য চেয়ারম্যান কার্যালয় কোন ভাবেই
                      দ্বায়ী থাকিবে না এবং উক্ত সনদ বাতিল বলিয়া গণ্য করা হইবে ।
                    </p>
                  </div>
                </div>
                <CertificateFooterBn certificate={certificate} accountType={userInfo?.account} marginHandel={false}
                  sigbature={true}
                  sigbatureConecnt="সনাক্তকারী"
                />
              </div>

              <CertificatePagesLogo
                certificate={certificate}
                toValue={'100px'}
              />
            </div>
          </div>
        </div>
      }
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnWarishCertificate)
