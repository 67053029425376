import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { usePostHoldingTaxMutation } from "../../app/services/holdingTax";
import { date } from "yup";


function CretaeBrithCertificate({ contects, modal, setOpen }) {
  const { type } = modal;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  
  const [amountPerSchedule, setAmountPerSchedule] = useState(0);
  const [actualTaxAmount, setActualTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [postData, { isSuccess,isLoading, isError, error }] = usePostHoldingTaxMutation();

  const createOnSubmit = async (data) => {


  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("সঠিকভাবে  নিবন্ধন করা হয়েছে");
      setOpen({ type: false }, reset(), setTotal(0))
    }
    if (error) {
      toast.error(error?.data?.error);
    }
  }, [isSuccess, error, reset]);

  useEffect(() => {
    if (actualTaxAmount) {
      setTotal(actualTaxAmount);
    }
  }, [actualTaxAmount]);

  return (
    <>
      {type && (
        <div className="modal_product p-6">
          <section className="content-mainDash ">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button
                    onClick={() =>
                      setOpen({ type: false }, reset(), setTotal(0))
                    }
                  >
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="card ">
                  <div className="card-body">
                    <div className="row g-3"> 

                      <div className="col-lg-4">
                        <label className="form-label">
                        জন্মনিবন্ধন নং<span className="from_required">*</span>{" "}
                        </label>
                        <input
                        id="birth registration"
                          className="form-control"
                          type="text"
                          {...register("birth_registration", {
                            // required: "খালি রাখা যাবে না",
                            // pattern: {
                            //   value: /^[ঀ-৾\s:-]+$/i,
                            //   message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            // },
                          })}
                        />
                        {/* {errors?.name_bangla && (
                          <span className="form__error">
                            {errors?.name_bangla.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                        জাতীয় পরিচয়পত্র নং{" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("nid", {
                            // required: "খালি রাখা যাবে না",
                            // pattern: {
                            //   value: /^[ঀ-৾\s:-]+$/i,
                            //   message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            // },
                          })}
                        />
                        {errors?.father_husband_bangla && (
                          <span className="form__error">
                            {errors?.father_husband_bangla.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          জন্ম তারিখ{" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("date_of_birth", {
                            // pattern: {
                            //   value: /^[ঀ-৾\s:-]+$/i,
                            //   message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            // },
                          })}
                        />
                        {errors?.mother_name_bangla && (
                          <span className="form__error">
                            {errors?.mother_name_bangla.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">
                           লিঙ্গ<span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type=""
                          {...register("gender", {
                            // pattern: {
                            //   value: /^[0-9]{11}$/,
                            //   message: "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে",
                            // },
                          })}
                        />
                        {errors?.number && (
                          <span className="form__error">
                            {errors?.number.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          মাতার নাম{" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type=""
                          {...register("mother_name", {
                            // pattern: {
                            //   value: /^[0-9]{10}$|^[0-9]{17}$/,
                            //   message:
                            //     "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে",
                            // },
                          })}
                        />
                        {/* {errors?.nid && (
                          <span className="form__error">
                            {errors?.nid.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 ">
                        <label htmlFor="ward" className="form-label">
                          পিতার নাম<span className="from_required">*</span>{" "}
                        </label>
                        <input
                          id="ward"
                          name="ward"
                          className="form-control"
                          type=""
                          {...register("father_name", {
                          })}
                        //   onChange={(e) => {
                        //     const value = e.target.value;
                        //   }}
                        />
                        {/* {errors?.ward_no && (
                          <span className="form__error">
                            {errors?.ward_no.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label htmlFor="village_bn_name" className="form-label">
                          গ্রাম (বাংলা)
                          <span className="from_required">*</span>{" "}
                        </label>
                        <input
                          id="village_bn_name"
                          name="village_bn_name"
                          className="form-control"
                          type="text"
                          {...register("village_bn_name", {
                            // required: "খালি রাখা যাবে না",
                            // pattern: {
                            //   value: /^[ঀ-৾\s:-]+$/i,
                            //   message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            // },
                          })}
                        //   onChange={(e) => {
                        //     const value = e.target.value;
                        //   }}
                        />
                        {/* {errors?.village_bn_name && (
                          <span className="form__error">
                            {errors?.village_bn_name.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 mb-2">
                        <label htmlFor="prRoad" className="form-label">
                        বই{" "}
                        </label>
                        <input
                          id="road"
                          name="road"
                          className="form-control"
                          type="text"
                          {...register("book_no", {
                          })}
                        //   onChange={(e) => {
                        //     const value = e.target.value;
                        //   }}
                        />
                        {/* {errors?.road && (
                          <span className="form__error">
                            {errors?.road.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 mb-2">
                        <label htmlFor="home_type" className="form-label">
                          ওয়ার্ড নং
                        </label>
                        <input
                          id="home_type"
                          name="home_type"
                          className="form-control"
                          type="text"
                          {...register("ward_no", {
                            // required: "খালি রাখা যাবে না",
                            // pattern: {
                            //   value: /^[ঀ-৾\s:-]+$/i,
                            //   message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            // },
                          })}
                        //   onChange={(e) => {
                        //     const value = e.target.value;
                        //   }}
                        />
                        {/* {errors?.home_type && (
                          <span className="form__error">
                            {errors?.home_type.message}
                          </span>
                        )} */}
                      </div>

                      <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                        Leaf পাতা
                        </label>
                        <input
                          id=""
                          name="e"
                          className="form-control"
                          type=""
                          {...register("leaf_no", {
                          })}
                        //   onChange={(e) => {
                        //     const value = Number(e.target.value);
                        //     setAmountPerSchedule(value);
                        //   }}
                        />
                        {/* {errors?.amount_as_schedule && (
                          <span className="form__error">
                            {errors?.amount_as_schedule.message}
                          </span>
                        )} */}
                      </div>
                   

                   
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <p
                    onClick={() =>
                      setOpen({ type: false }, reset(), setTotal(0))
                    }
                    className="btn btn-md rounded btn-warning bg-warning font-sm hover-up"
                  >
                    বাতিল করুন
                  </p>
                  <>
                    <input
                      style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                      type="submit"
                      className="btn btn-md rounded font-sm hover-up h-25"
                      value="সংরক্ষণ করুন"
                    />
                  </>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default CretaeBrithCertificate;

export const home_type = [
  "মাটির ঘর",
  "টিনের ঘর",
  "আধা পাকা ঘর",
  "আধা পাকা ইমারত",
  "পাকা ইমারত",
];
