import ReactToPrint from 'react-to-print';
import styles from './BnTransferofconstituency.module.css';
import { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { connect } from 'react-redux';
import EnglishToBanglaNumber from '../../../../../utils/EnglishToBanglaNumber';
import { CertificateFooterBn, CertificateFooterEn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderBn } from '../../CertificateHeader/CertificateHeader';
import { RefetchData } from '../../../RefetchData';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';

const BnTransferofconstituency = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const componentRef = useRef();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
    const certificate = data?.certificate;
    useEffect(() => {
        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch]);

    return (
        <DashboardLayout>
            {
                isLoading ? <GetSpinner /> : <div>
                    <div className="download_pdf d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn btn-md justify-content-center">
                                    প্রিন্ট করুন
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                    <div className={styles.body} ref={componentRef}>
                        <div className={styles.container}>
                            <div className={styles.content_position}>
                                <div>
                                    <CertificateHeaderBn certificate={certificate} sonodName={'ভোটার এলাকা স্থানান্তর প্রত্যয়ন'} />
                                    <div>
                                        <p className={styles.mainText}>
                                            এই মর্মে প্রত্যয়ন করা যাইতেছে যে,  <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b>, পিতা/স্বামীঃ {' '}
                                            <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_bangla}</b>, মাতাঃ {' '}
                                            <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.mother_name_bangla}</b>,
                                            তাহার   <b style={{ fontWeight: 'bold' }}>স্থায়ী ঠিকানা:- </b>
                                            গ্রামঃ  {' '}{certificate?.citizen_id?.permanent_address[0]?.village_bn_name},
                                            বাসা/হোল্ডিং {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.holding_no}`)}, 
                                            ওয়ার্ডঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.ward_no}`)},
                                            ডাকঘরঃ{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.post_office_bn},
                                            ইউনিয়নঃ{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.union?.bn_name},
                                            উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}। 
                                            <b style={{ fontWeight: 'bold' }}> বর্তমান ঠিকানা- </b>
                                            গ্রামঃ  {' '}{certificate?.citizen_id?.present_address[0]?.village_bn_name},
                                            বাসা/হোল্ডিং {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.holding_no}`)}, ওয়ার্ডঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.ward_no}`)},
                                            ডাকঘরঃ{' '}
                                            {certificate?.citizen_id?.present_address[0]?.post_office_bn}, 
                                            ইউনিয়নঃ{' '}
                                            {certificate?.citizen_id?.present_address[0]?.union?.bn_name},
                                            
                                             উপজেলাঃ {certificate?.citizen_id?.present_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                                            {certificate?.citizen_id?.present_address[0]?.district?.bn_name}। {' '}
                                            তিনি অত্র এলাকায় স্থায়ীভাবে বসবাস করেছেন এবং বাংলাদেশের নাগরিক। তাহার পূর্বের ভোটার এলাকা পরিবর্তন করে অত্র এলাকার ভোটার হিসেবে নিবন্ধন করা যায়।
                                        </p>
                                        <br />

                                        <div >
                                            <p className={styles.mainText}>আমি তাহার উজ্জ্বল ভবিষ্যৎ ও সর্বাঙ্গিন মঙ্গল কামনা করি।।</p>
                                        </div>
                                    </div>
                                </div>
                                <CertificateFooterBn certificate={certificate} accountType={userInfo?.account} marginHandel={true} />
                            </div>
                            <CertificatePagesLogo
                                certificate={certificate}
                                toValue={'200px'}
                            />
                        </div>
                    </div>
                </div>
            }


        </DashboardLayout >
    );
};

function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnTransferofconstituency)



