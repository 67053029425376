import ReactToPrint from 'react-to-print';
import { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../../layout/DashboardLayout';
import styles from './Enattestationofthesameperson.module.css';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { CertificateFooterEn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderEn } from '../../CertificateHeader/CertificateHeader';
import { RefetchData } from '../../../RefetchData';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import { connect } from 'react-redux';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';

const Enattestationofthesameperson = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const componentRef = useRef();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
    const accountType = userInfo?.account
    const certificate = data?.certificate;
    useEffect(() => {
        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch]);

    return (
        <DashboardLayout>
            {
                isLoading ? <GetSpinner /> : <div>
                    <div className="download_pdf d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn btn-md justify-content-center">
                                    প্রিন্ট করুন
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                    <div className={styles.body} ref={componentRef}>
                        <div className={styles.container} >
                            <div className={styles.content_position}>
                                <div>
                                    <CertificateHeaderEn certificate={certificate} sonodName={'Attestation of the same person'} />
                                    <div>
                                        <p className={styles.mainText}>
                                            This is to certify that, <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_english}</b>,
                                            Father / Husband:{' '}
                                            <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_english}</b>, Mother:{' '}
                                            <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.mother_name_english}</b>,
                                            His <b style={{ fontWeight: 'bold' }}>Permanent Address:-{' '}</b>
                                            Village / House:{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.village_en_name},
                                            Holding No:{' '} {certificate?.citizen_id?.permanent_address[0]?.holding_no},
                                            Post Office:{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.post_office_en}, Ward No: {certificate?.citizen_id?.permanent_address[0]?.ward_no},
                                            Upazila: {" "}
                                            {certificate?.citizen_id?.permanent_address[0]?.upazila?.name}, District:{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.district?.name}.
                                            <b style={{ fontWeight: 'bold' }}>{' '}Present Address:-{' '}</b>
                                            Village / House:{' '}
                                            {certificate?.citizen_id?.present_address[0]?.village_en_name},
                                            Holding No:{' '} {certificate?.citizen_id?.present_address[0]?.holding_no},
                                            Post Office:{' '}
                                            {certificate?.citizen_id?.present_address[0]?.post_office_en}, Ward No: {certificate?.citizen_id?.present_address[0]?.ward_no},
                                            Upazila: {" "}
                                            {certificate?.citizen_id?.present_address[0]?.upazila?.name}, District:{' '}
                                            {certificate?.citizen_id?.present_address[0]?.district?.name}  is known to me.  Although his name is <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id ?.name_english}</b> in the
                                            birth registration / national identity document and <b style={{ fontWeight: 'bold' }}>{certificate?.samePerson?.name_en}</b> is mentioned in some other documents, he is the
                                            same person.
                                        </p>

                                        <br />
                                      {/*   <div className={styles.mainText}>
                                            <p>I wish him all the best and a bright future.</p>
                                        </div> */}
                                    </div>
                                </div>
                                <CertificateFooterEn certificate={certificate} accountType={accountType} marginHandel={true} />
                            </div>
                            <CertificatePagesLogo
                                certificate={certificate}
                                toValue={'200px'}
                            />
                        </div>
                    </div>


                </div>
            }

        </DashboardLayout>
    );
};

function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Enattestationofthesameperson)
