import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import { RefetchData } from "../../../../components/elements/RefetchData";
import styles from './Enmunicipalitycitizenship.module.css';
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import ReactToPrint from "react-to-print";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
import { connect } from "react-redux";
import { CertificateHeaderEn } from "../../../../components/elements/applicationCertificate/CertificateHeader/CertificateHeader";
import { CertificateFooterEn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";


const Enmunicipalitycitizenship = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);
  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}>
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderEn certificate={certificate} sonodName={'Citizenship Certificate'} />
                  <div>
                    <p className={styles.mainText}>
                      This is to certify that, <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_english}</b>,
                      National ID/Birth Registration No:
                      <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.nid}</b>, Father/Husband:{' '}
                      <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_english}</b>, Mother:{' '}
                      <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.mother_name_english}</b>, His{' '}

                      <b style={{ fontWeight: 'bold' }}>Permanent Address: </b>  Village/House: {' '}
                      {certificate?.citizen_id?.permanent_address[0]?.village_en_name}, Road/Block/Sector:  {' '}
                      {certificate?.citizen_id?.permanent_address[0]?.road}, Post Office: {certificate?.citizen_id?.permanent_address[0]?.post_office_en},
                      Holding No: {certificate?.citizen_id?.permanent_address[0]?.holding_no},{' '}
                      Ward No: {certificate?.citizen_id?.permanent_address[0]?.ward_no},{' '}
                      {
                        accountType === 'municipality' ? `Municipal: ` : 'Union:'
                      }
                      {" "}{certificate?.union_or_municipality?.municipality_union_en_name}, {" "}
                      Upazila: {" "}
                      {certificate?.citizen_id?.permanent_address[0]?.upazila?.name}, District:{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.name}, {' '}

                      <b style={{ fontWeight: 'bold' }}>Present Address:</b>  Village/House: {' '}
                      {certificate?.citizen_id?.present_address[0]?.village_en_name}, Road/Block/Sector:  {' '}
                      {certificate?.citizen_id?.present_address[0]?.road}, Post Office: {certificate?.citizen_id?.permanent_address[0]?.post_office_en},
                      Ward No: {certificate?.citizen_id?.present_address[0]?.ward_no},{' '}
                      Municipality: {certificate?.union_or_municipality?.municipality_union_en_name},{" "}
                      Upazila: {" "}
                      {certificate?.citizen_id?.present_address[0]?.upazila?.name}, District:{' '}
                      {certificate?.citizen_id?.present_address[0]?.district?.name}, He is a resident of this area and
                      Bangladeshi citizen by birth
                    </p>
                    <br />

                    <div className={styles.mainText}>
                      <p>I wish him all the best and a bright future. </p>
                    </div>
                  </div>
                </div>
                <CertificateFooterEn certificate={certificate} accountType={accountType} marginHandel={true} />
              </div>

              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>
        </div>
      }

    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Enmunicipalitycitizenship)
