import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import { RefetchData } from "../../../../components/elements/RefetchData";
import styles from './Bnmunicipalityunmarried.module.css';
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import ReactToPrint from "react-to-print";
import EnglishToBanglaNumber from "../../../../utils/EnglishToBanglaNumber";
import { CertificateFooterBn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
import { connect } from "react-redux";
import { CertificateHeaderBn } from "../../../../components/elements/applicationCertificate/CertificateHeader/CertificateHeader";




const Bnmunicipalityunmarried = ({ userInfo }) => {

  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container} >
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderBn certificate={certificate} sonodName={'অবিবাহিত সনদ'} />
                  <div>
                    <p className={styles.mainText}>
                      এই মর্মে প্রত্যয়ন করা যাইতেছে যে,  <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b>, পিতা/স্বামীঃ {' '}
                      <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_bangla}</b>, মাতাঃ {' '}
                      <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.mother_name_bangla}</b>, গ্রামঃ  {' '}{certificate?.citizen_id?.permanent_address[0]?.village_bn_name},
                      বাসা/হোল্ডিং {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.holding_no}`)},
                      ওয়ার্ডঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.ward_no}`)},
                      ডাকঘরঃ{' '}{certificate?.citizen_id?.permanent_address[0]?.post_office_bn},
                      পৌরসভাঃ{' '} {certificate?.union_or_municipality?.municipality_union_bn_name},
                      উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}। {' '} তিনি অত্র এলাকার একজন স্থায়ী বাসিন্দা। আমার জানামতে বর্তমানে তিনি অবিবাহিত ।
                    </p>
                    <br />

                    <p className={styles.mainText}>আমি তাহার উজ্জ্বল ভবিষ্যৎ ও সর্বাঙ্গিন মঙ্গল কামনা করি। </p>

                  </div>
                </div>
                <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={true} />
              </div>
              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>


        </div>

      }

    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Bnmunicipalityunmarried)