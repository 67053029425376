import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQueryHandel';
export const CertificateDeathApi = createApi({
    reducerPath: 'DeathCertificate',
    tagTypes: ['Death'],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        postCertificateDeathAdd: builder.mutation({
            query: (data) => ({
                url: `v1/death/certificate`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Death'],
        }),
        reportCertificateType: builder.mutation({
            query: (data) => ({
                url: `v1/certificate/type/report`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Death'],
        }),
        getDeathSonod: builder.query({
            query: (pathname) => ({
                url: `v1/death/certificate?${pathname}`,
                method: 'GET',
            }),
            providesTags: ['Death'],
        }),
        getSingleDeathCertificate: builder.query({
            query: (id) => ({
                url: `v1/death/certificate/${id}`,
                method: 'GET',
            }),
            providesTags: ['Death'],
        }),
        updateDeathCertificate: builder.mutation({
            query: ({ id, data }) => ({
                url: `/v1/death/certificate/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Death'],
        }),
        deleteDeathCertificate: builder.mutation({
            query: (id) => ({
                url: `v1/death/certificate/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Death'],
        }),

    }),
});
export const {
    usePostCertificateDeathAddMutation, useDeleteDeathCertificateMutation,
    useGetDeathSonodQuery,
    useGetSingleDeathCertificateQuery, useUpdateDeathCertificateMutation,
    useReportCertificateTypeMutation



} = CertificateDeathApi;



