import DashboardLayout from "../components/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDashboardQuery } from "../app/services/dashboard";
import GetSpinner from "../helpers/shared/GetSpinner";
import EnglishToBanglaNumber from "../utils/EnglishToBanglaNumber";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { TbNavigationCheck, TbSmartHomeOff, TbFileCertificate, TbCertificateOff, TbReceipt2 } from "react-icons/tb";
import { LuShieldClose } from "react-icons/lu";
import { BsBarChartFill, BsPersonFillCheck } from "react-icons/bs";
import { RiHome7Fill } from "react-icons/ri";
import { MdOutlineHomeWork, MdAddHome } from "react-icons/md";
import { IoIosPeople, IoMdClose } from "react-icons/io";
import { BsPersonFillX } from "react-icons/bs";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { PiReceiptXBold } from "react-icons/pi";
import { FaRegCalendar } from "react-icons/fa";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
const Home = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const accessAdmin = ["admin"];
  
    // Dynamically calculate today's date and the date one week ago
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
  
    // Initialize the date range state
    const [value, setValue] = useState([lastWeek, today]);
  
    // Format dates for the query
    const dateQuery = `fromDate=${value[0].toISOString()}&toDate=${value[1].toISOString()}`;
  
    // Fetch dashboard data
    const { data } = useDashboardQuery(dateQuery);
  
    useEffect(() => {
      if (!accessAdmin.includes(userInfo?.role)) {
        console.warn("Access denied. Admins only.");
      }
    }, [userInfo, accessAdmin]);
    return (
        <DashboardLayout>
            <div className=" content-header">
                <div>
                    <h2 className="content-title card-title">ড্যাশবোর্ড</h2>

                </div>
                <DateRangePicker
                  calendarIcon={<FaRegCalendar/>}
                  clearIcon={<IoMdClose/>}
                  value={value}
                  onChange={setValue}
                />
                {/*  <p>এখানে আপনার ব্যবসা সম্পর্কে সম্পূর্ণ তথ্য</p> */}
            </div>
            {
                data ? <>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><FaHandHoldingDollar
                                        className="text-success"
                                    /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.subscriptionInfo?.totalAmount[0]?.amount ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.totalAmount[0]?.amount?.toFixed(2)}`) : '০ '
                                        }
                                        </span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <TbNavigationCheck className="text-success" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের সফল</h6>
                                        <span>{
                                            data?.subscriptionInfo?.statusTrueCount[0]?.count ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.statusTrueCount[0]?.count}`) : '০'
                                        }
                                        </span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><LuShieldClose className="text-warning" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের বিচারাধীন</h6>
                                        <span>{
                                            data?.subscriptionInfo?.statusFalseCount[0]?.count ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.statusFalseCount[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>

                        {
                            accessAdmin?.includes(userInfo?.users?.role) && <>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-primary-light"><BsBarChartFill className="text-success" /></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">মোট আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.totalUser[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.totalUser[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-primary-light"><RiHome7Fill className="text-success" /></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">ইউনিয়ন  আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.union[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.union[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-primary-light"><MdOutlineHomeWork className="text-success" /></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">পৌরসভা আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.municipality[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.municipality[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-primary-light"><MdAddHome className="text-warning" /></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">নতুন আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.noAcpt[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.noAcpt[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </>
                        }




                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><IoIosPeople className="text-success" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">মোট নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenTotal[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenTotal[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><BsPersonFillCheck className="text-success" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সফল নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenTrue[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenTrue[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><BsPersonFillX className="text-warning" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">বিচারাধীন নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenFalse[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenFalse[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><TbSmartHomeOff className="text-success" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">নতুন আবেদন</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenFalse[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenFalse[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><TbFileCertificate className="text-success" /></span>

                                    <div className="text">
                                        <h6 className="mb-1 card-title">মোট সনদপত্র </h6>
                                        <span>{
                                            data?.certificateInfo?.approved[0]?.count ? EnglishToBanglaNumber(`${data?.certificateInfo?.approved[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><TbCertificateOff className="text-warning" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">নতুন সনদপত্র</h6>
                                        <span>{
                                            data?.certificateInfo?.pending[0]?.count ? EnglishToBanglaNumber(`${data?.certificateInfo?.pending[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><TbReceipt2 className="text-success" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">হোল্ডিং ট্যাক্সের মোট পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_total?.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><HiMiniReceiptPercent className="text-success" />
                                    </span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">আদায়কৃত হোল্ডিং ট্যাক্সের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_true?.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><PiReceiptXBold className="text-warning" /></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">
                                            বকেয়া হোল্ডিং ট্যাক্সের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_false?.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                </> : <GetSpinner />
            }


        </DashboardLayout >
    );
}

export default Home;