import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HeroSlider from './HeroSlider'

// icons import
import { GrFormDown } from "react-icons/gr"

export default function ServicesNav() {
    return (
        <div>
            <HeroSlider />

            <div className="serviceNavbar py-2 ">
                <div className="navbar-nav ">
                    <div className=" d-flex flex-wrap align-items-center gap-1 position-relative">
                        <Link className='navitem' to='/'>প্রথম পাতা</Link>
                        <Link className='navitem' to='/'>সেবাসমূহ</Link>

                        <Link className='navitem' to='/'>আবেদনর অবস্থা জানুন </Link>
                        <div className='navitem'>
                            <div class="dropdown">
                                <Link to='/' className='dropdownitem' id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    সনদ যাচাই <GrFormDown className=' dropdownIcon' />
                                </Link>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <p>  <Link className='dropnavitem navitem' to='/verifySonod'>ইউনিয়ন</Link></p>
                                    <p>  <Link className='dropnavitem navitem' to='/municipality/verifySonod'>পৌরসভা</Link> </p>
                                </ul>
                            </div>
                        </div>

                        <Link className='navitem' to='/terms'>ব্যবহারের শর্তাবলি</Link>

                        <Link className='navitem' to='/'>ষ্মার্ট বাংলাদেশ</Link>

                        <Link className='navitem' to='/commincation'>যোগাযোগ</Link>



                    </div>
                </div>
            </div>

        </div>
    )
}
