import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useGetSingleCitizenMutation } from '../../../../app/services/citizen';
import { FimilyCertificateSchema } from '../../../../helpers/validation/AllSonodCertificateSchema';
import { useCreatemunicipalitycertificateMutation } from '../../../../app/services/certificatemunicipality';
import FindcitizensSearch from '../../../findcitizens/FindcitizensSearch';
import RelationshipSelect from '../../../../helpers/shared/RelationshipSelect';
import Button from '../../../button/Button';
/*  */
function CreateFamilyCertificatemunicipality({ userInfo, contects, modal, setOpen, }) {
    const { type } = modal;
    const [userData, setUserData] = useState(false);
    const [nidOrNumber, setNidOrNumber] = useState('');

    const [familyDetails, setFamilyDetails] = useState([])
    const [familyDtError, setFamilyDtError] = useState(null)
    const memberInitialData = { memberNameBn: "", memberNameEn: "", memberDate: "", memberNidDeath: "", memberRelation: "" }
    const [memberData, setMemberData] = useState(memberInitialData)
    const { register, handleSubmit, reset, setValue, formState: { errors, }, } = useForm({ resolver: yupResolver(FimilyCertificateSchema)});
    const [getCitizen, { data, isLoading }] = useGetSingleCitizenMutation();
    const handleSearchCitizen = async () => {
        if (nidOrNumber) {
            const searchData = { nidOrNumber };
            await getCitizen(searchData);
        }
    };
    const [createCertificate, { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err },] = useCreatemunicipalitycertificateMutation();



    const handleInputChange = (e) => {
        setFamilyDtError(null)
        const { name, value } = e.target;
        setMemberData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    // Function to handle input changes
    const addMemberEditInputData = (index, field, value) => {
        const updatedFamilyDetails = [...familyDetails];
        updatedFamilyDetails[index][field] = value;
        setMemberData(updatedFamilyDetails);
    };
    const handleAddFamilyDetails = () => {
        if (
            memberData.memberNameBn === "" ||
            memberData.memberRelation === ""
        ) {
            setFamilyDtError("খালি রাখা যাবে ন");
        } else {
            const updatedFamilyDetails = [...familyDetails,memberData ];
            setFamilyDetails(updatedFamilyDetails);
            setMemberData(memberInitialData);

        }
    };
    const handleDeleteFamilyMember = (index) => {
        const updatedFamilyDetails = [...familyDetails];
        updatedFamilyDetails.splice(index, 1);
        setFamilyDetails(updatedFamilyDetails);
    };

    const createOnSubmit = async (certificateData) => {
        if (familyDetails?.length >= 1) {
            const removeId = { ...userData }
            delete removeId._id
            const certificateDataInfo = {
                ...removeId,
                status: userInfo?.role == 'secretary' ? 'approved' : 'pending',
                sonod_type: "family",
                citizen_id: userData?._id,
                family_information: familyDetails
            }
            await createCertificate(certificateDataInfo)
        } else {
            toast.error("পারিবারিক তথ্য যোগ করুন")
        }
    };



    useEffect(() => {
        if (userData) {
            setValue('sonod_type', userData?.sonod_type);
            setValue('nid', userData?.nid);
            setValue('number', userData?.number);
            setValue('name_bangla', userData?.name_bangla);
            setValue('name_english', userData?.name_english);
            setValue('father_husband_bangla', userData?.father_husband_bangla);
            setValue('father_husband_english', userData?.father_husband_english);
            setValue('mother_name_bangla', userData?.mother_name_bangla);
            setValue('mother_name_english', userData?.mother_name_english);
            setValue('date_of_birth', userData?.date_of_birth);
            setValue('gender', userData?.gender);
            setValue('blood_group', userData?.blood_group);
            setValue('district', userData?.permanent_address[0]?.district?.bn_name);
            setValue('division', userData?.permanent_address[0]?.division?.bn_name);
            setValue('upazila', userData?.permanent_address[0]?.upazila?.bn_name);
            setValue('union', userData?.permanent_address[0]?.union?.bn_name);
            setValue('municipality_bn_name', userData?.permanent_address[0]?.municipality_bn_name);
            setValue('municipality_en_name', userData?.permanent_address[0]?.municipality_en_name);
            setValue('post_office_bn', userData?.permanent_address[0]?.post_office_bn);
            setValue('post_office_en', userData?.permanent_address[0]?.post_office_en);
            setValue('village_bn_name', userData?.permanent_address[0]?.village_bn_name);
            setValue('village_en_name', userData?.permanent_address[0]?.village_en_name);
            setValue('holding_no', userData?.permanent_address[0]?.holding_no);
            setValue('ward_no', userData?.permanent_address[0]?.ward_no);
            setValue('road', userData?.permanent_address[0]?.road);
            setValue('pdistrict', userData?.present_address[0]?.district?.bn_name);
            setValue('pdivision', userData?.present_address[0]?.division?.bn_name);
            setValue('pupazila', userData?.present_address[0]?.upazila?.bn_name);
            setValue('punion', userData?.present_address[0]?.union?.bn_name);
            setValue('pmunicipality_bn_name', userData?.present_address[0]?.municipality_bn_name);
            setValue('pmunicipality_en_name', userData?.present_address[0]?.municipality_en_name);
            setValue('ppost_office_bn', userData?.present_address[0]?.post_office_bn);
            setValue('ppost_office_en', userData?.present_address[0]?.post_office_en);
            setValue('pvillage_bn_name', userData?.present_address[0]?.village_bn_name);
            setValue('pvillage_en_name', userData?.present_address[0]?.village_en_name);
            setValue('pholding_no', userData?.present_address[0]?.holding_no);
            setValue('pward_no', userData?.present_address[0]?.ward_no);
            setValue('proad', userData?.present_address[0]?.road);
        }
    }, [userData, setValue]);

    useEffect(() => {
        if (isSucc) {
            toast.success('সঠিকভাবে নাগরিক এড করা হয়েছ');
            setOpen({ type: false }, reset())
        }
        if (isErr) {
            toast.error(err?.data?.error);
        }
    }, [isSucc, isErr, err]);



    return (
        <>
            {type && (
                <div className="modal_product">
                    <section className="content-mainDash">
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header" >
                                    <h2 className="content-title">{contects?.title}</h2>
                                    <button onClick={() => setOpen({ type: false }, reset())}>
                                        X
                                    </button>
                                </div>
                                <FindcitizensSearch
                                    onChangeGetValue={setNidOrNumber}
                                    onClickSubmitButton={handleSearchCitizen}
                                    isLoading={isLoading}
                                    setInputFildData={setUserData}
                                    data={data?.data}
                                />
                            </div>
                            <form onSubmit={handleSubmit(createOnSubmit)}>
                                <div className="col-lg-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row gx-3">
                                                <div className="col-4 mb-3">
                                                    <label className="form-label">
                                                        নাম: (বাংলা){' '}
                                                        <span className="from_required">*</span>{' '}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="নাম বাংলা"
                                                        {...register('name_bangla', { required: true })}
                                                    />
                                                    {errors?.name_bangla && (
                                                        <span className="form__error">
                                                            {errors?.name_bangla.message}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        নাম: (ইংরেজিতে){' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="নাম ইংরেজিতে"
                                                        {...register('name_english', { required: true })}
                                                    />
                                                    {errors?.name_english && (
                                                        <span className="form__error">
                                                            {errors?.name_english.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        পিতা / স্বামীর নাম: (বাংলা){' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="পিতা / স্বামীর নাম বাংলা"
                                                        {...register('father_husband_bangla', {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors?.father_husband_bangla && (
                                                        <span className="form__error">
                                                            {errors?.father_husband_bangla.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        পিতা / স্বামীর নাম: (ইংরেজিতে){' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                                                        {...register('father_husband_english', {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors?.father_husband_english && (
                                                        <span className="form__error">
                                                            {errors?.father_husband_english.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মাতার নাম: (বাংলা){' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="মাতার নাম বাংলা"
                                                        {...register('mother_name_bangla', {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors?.mother_name_bangla && (
                                                        <span className="form__error">
                                                            {errors?.mother_name_bangla.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মাতার নাম: (ইংরেজিতে){' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="মাতার নাম ইংরেজিতে"
                                                        {...register('mother_name_english', {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors?.mother_name_english && (
                                                        <span className="form__error">
                                                            {errors?.mother_name_english.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        মোবাইল নম্বর{' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="মোবাইল নম্বর"
                                                        {...register('number', { required: true })}
                                                    />
                                                    {errors?.number && (
                                                        <span className="form__error">
                                                            {errors?.number.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        জাতীয় পরিচয়পত্র নং{' '}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="জাতীয় পরিচয়পত্র নং"
                                                        {...register('nid', { required: true })}
                                                    />
                                                    {errors?.nid && (
                                                        <span className="form__error">
                                                            {errors?.nid.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label className="form-label">
                                                        জন্ম তারিখ <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="জন্ম তারিখ"
                                                        {...register('date_of_birth', { required: true })}
                                                    />
                                                    {errors?.date_of_birth && (
                                                        <span className="form__error">
                                                            {errors?.date_of_birth.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>



                                            {/* ========== fimily certificate ==========  */}
                                            <div className='d-flex justify-content-between align-items-center mb-3' >
                                                <h5> পরিবারের সদস্যদের তথ্য :-</h5>

                                                <button onClick={handleAddFamilyDetails} type='button' className=' btn-sm btn-primary '>
                                                    যোগ করুন +
                                                </button>
                                            </div>
                                            <div>
                                                <div className="row gx-3 mb-2" style={{
                                                    border: '1px solid #3bb77e',
                                                    padding: '2px',
                                                    borderRadius: '6px'
                                                }}>
                                                    <div className="col-3 mb-3">
                                                        <label className="form-label">
                                                            নাম: (বাংলা){' '}
                                                            <span className="from_required">*</span>{' '}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name='memberNameBn'
                                                            placeholder="নাম বাংলা"
                                                            value={memberData.memberNameBn}
                                                            onChange={handleInputChange}
                                                        />

                                                    </div>
                                                    <div className="col-3 mb-3">
                                                        <label className="form-label">
                                                            নাম: (ইংরেজিতে){' '}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name='memberNameEn'
                                                            placeholder="নাম ইংরেজিতে"
                                                            value={memberData.memberNameEn}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className="col-2 mb-3">
                                                        <label className="form-label">
                                                            এনআইডি / জন্ম নিবন্ধন {' '}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name='memberNidDeath'
                                                            placeholder=" এনআইডি বা  জন্ম নিবন্ধন "
                                                            value={memberData.memberNidDeath}
                                                            onChange={handleInputChange}
                                                        />

                                                    </div>

                                                    <div className="col-2 mb-3">
                                                        <label className="form-label">
                                                            তারিখ
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            name='memberDate'
                                                            value={memberData.memberDate}
                                                            onChange={handleInputChange}
                                                        />

                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <label htmlFor="Promo_amount" className="form-label">
                                                            সম্পর্ক <span className="from_required">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name="memberRelation"
                                                            value={memberData.memberRelation}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">
                                                                সম্পর্ক নির্বাচন করুন
                                                            </option>
                                                            {RelationshipSelect?.map((relationship, index) => <option
                                                                key={index}
                                                                value={relationship?.value}>{relationship?.name}</option>)}

                                                        </select>
                                                    </div>
                                                </div>
                                                <p style={{ color: "red" }}>{familyDtError}</p>



                                                <div className=' mt-4' style={{ paddingTop: "25px" }} >
                                                    {
                                                        familyDetails.map((details, index) => <div className="row gx-3 mb-4 shadow-sm" key={index}>
                                                            <div className="col-3 mb-3">
                                                                <label className="form-label">
                                                                    নাম: (বাংলা){' '}
                                                                    <span className="from_required">*</span>{' '}
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name='memberNameBn'
                                                                    value={details?.memberNameBn}
                                                                    onChange={(e) => addMemberEditInputData(index, 'memberNameBn', e.target.value)}
                                                                />

                                                            </div>
                                                            <div className="col-2 mb-3">
                                                                <label className="form-label">
                                                                    নাম: (ইংরেজিতে){' '}
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name='memberNameEn'
                                                                    value={details?.memberNameEn}
                                                                    onChange={(e) => addMemberEditInputData(index, 'memberNameEn', e.target.value)}
                                                                />

                                                            </div>
                                                            <div className="col-2 mb-3">
                                                                <label className="form-label">
                                                                    এনআইডি / জন্ম নিবন্ধন {' '}
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    name='memberNidDeath'
                                                                    value={details?.memberNidDeath}
                                                                    onChange={(e) => addMemberEditInputData(index, 'memberNidDeath', e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-2 mb-3">
                                                                <label className="form-label">
                                                                    তারিখ
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type='date'
                                                                    name='memberDate'
                                                                    value={details?.memberDate}
                                                                    onChange={(e) => addMemberEditInputData(index, 'memberDate', e.target.value)}
                                                                />

                                                            </div>

                                                            <div className="col-2 mb-3">
                                                                <label className="form-label">
                                                                    সম্পর্ক
                                                                </label>
                                                                <select
                                                                    className="form-select"
                                                                    name="memberRelation"
                                                                    onChange={(e) => addMemberEditInputData(index, 'memberRelation', e.target.value)}
                                                                >
                                                                    {RelationshipSelect?.map((relationship, index) => <option
                                                                        key={index}
                                                                        value={relationship?.value}
                                                                        selected={relationship?.value == details?.memberRelation}
                                                                    >
                                                                        {relationship?.name}
                                                                    </option>)}

                                                                </select>



                                                            </div>

                                                            <div className=' col-1 d-flex align-items-center justify-content-end '>
                                                                <button type='button' onClick={() => handleDeleteFamilyMember(index)} className='btn btn-danger '>Delete</button>
                                                            </div>

                                                        </div>)
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <Button
                                    isLoading={loading}
                                    text="জমা করুন"
                                />
                            </form>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}


function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}



export default connect(mapStateToProps, null)(CreateFamilyCertificatemunicipality) 