import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDeleteCertificateMutation } from "../../app/services/certificate";
import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";
import SonodTypeEnToBn from "../../utils/SonodTypeEnToBn";
import { handelClick } from "../../utils/ConfirmDelete";
import AccountRoles from "../../utils/AccountRoles";
function WarishTable({ data, setStatus, setPrintModalOpen }) {
  const { userInfo } = useSelector((state) => state.auth);
  const [deleteContent, { isLoading }] = useDeleteCertificateMutation();
  const accessFunction = ["secretary"];

  return (
    <tr>
      <td>
        {
          userInfo?.users?.account === AccountRoles.MUNICIPALITY ? <Link to={`/application-certificate/municipality/${data?.sonod_type}/${data?._id}`}>
            {data?.warish_sonod_information[0]?.living_person_name_bn}
          </Link> : <Link to={`/application-certificate/${data?.sonod_type}/${data?._id}`}>
            {data?.warish_sonod_information[0]?.living_person_name_bn}
          </Link>
        }

      </td>
      <td>{EnglishToBanglaNumber(`${data?.citizen_id?.nid}`)}</td>
      <td>{EnglishToBanglaNumber(`${data?.citizen_id?.number}`)}</td>
      <td>
        {
          userInfo?.users?.account === AccountRoles.MUNICIPALITY && data?.citizen_id?.present_address[0]?.upazila?.bn_name
        }
        {userInfo?.users?.account === AccountRoles.UNION && data?.citizen_id?.present_address[0]?.union ? data?.citizen_id?.present_address[0]?.union?.bn_name : data?.citizen_id?.present_address?.includesmunicipalities}
      </td>
      <td className="text-capitalize ">{SonodTypeEnToBn(`${data?.sonod_type}`)}</td>
      <td>
        <p
          className={`badge ${data?.status === "pending"
            ? "badge-soft-danger"
            : data?.status === "approved"
              ? "badge-soft-success"
              : ""
            }`}
        >
          {data?.status === "pending" ? "গ্রহণ করা হয়নি" : "গ্রহণ করা হয়েছে"}
        </p>
      </td>
      <td className="text-end">
        <div className="text-end">
          {accessFunction?.includes(userInfo?.users?.role) && (
            <>
              {data?.status !== "approved" && (
                <button
                  onClick={() =>
                    setStatus({
                      type: true,
                      data: data,
                    })
                  }
                  style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                  disabled={data?.status === "approved"}
                  className="btn btn-sm font-sm btn-light rounded m-1 border"
                >
                  {" "}
                  <i className="material-icons md-edit fs-6"></i>{" "}
                </button>
              )}

              <button
                onClick={() => handelClick(data?._id, deleteContent)}
                style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                className="btn btn-sm font-sm btn-light rounded m-1"
              >
                {" "}
                <i className="material-icons md-delete_forever fs-6"></i>{" "}
              </button>
            </>
          )}

          <button
            onClick={() =>
              setPrintModalOpen({
                type: true,
                data: data,
              })
            }
            disabled={data?.status === "pending"}
            style={{ cursor: isLoading ? "no-drop" : "pointer" }}
            className="btn btn-sm font-sm btn-light rounded border m-1"
          >
            <i className="material-icons md-print fs-6"></i>{" "}
          </button>
        </div>
      </td>
    </tr>
  );
}

export default WarishTable;
