import ReactToPrint from 'react-to-print';
import { useEffect, useRef, useState } from 'react';
import styles from './Bnattestationofthesameperson.module.css';

import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import EnglishToBanglaNumber from '../../../../../utils/EnglishToBanglaNumber';
import { connect } from 'react-redux';
import { CertificateFooterBn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderBn } from '../../CertificateHeader/CertificateHeader';
import { RefetchData } from '../../../RefetchData';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';

const Bnattestationofthesameperson = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const componentRef = useRef();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
    const accountType = userInfo?.account
    const certificate = data?.certificate;
    useEffect(() => {
        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch]);
    return (
        <DashboardLayout>
            {
                isLoading ? <GetSpinner /> : <div>
                    <div className="download_pdf d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn btn-md justify-content-center">
                                    প্রিন্ট করুন
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                    <div className={styles.body} ref={componentRef}>
                        <div className={styles.container} >
                            <div className={styles.content_position}>
                                <div>
                                    <CertificateHeaderBn certificate={certificate} sonodName={'একই ব্যক্তির প্রত্যয়ন'} />
                                    <div>
                                        <p className={styles.mainText}>
                                            এই মর্মে প্রত্যয়ন করা যাইতেছে যে,  <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b>, পিতা/স্বামীঃ {' '}
                                            <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_bangla}</b>, মাতাঃ {' '}
                                            <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.mother_name_bangla}</b>,
                                            তাহার   <b style={{ fontWeight: 'bold' }}>স্থায়ী ঠিকানা:- </b>
                                            গ্রামঃ {certificate?.citizen_id?.permanent_address[0]?.village_bn_name}, 
                                            বাসা/হোল্ডিং {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.holding_no}`)}, 
                                            ওয়ার্ডঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.ward_no}`)},
                                            ডাকঘরঃ{' '}
                                            {certificate?.citizen_id?.permanent_address[0]?.post_office_bn}, 
                                            ইউনিয়নঃ{' '} {certificate?.citizen_id?.permanent_address[0]?.union?.bn_name},
                                            উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name},
                                            জেলাঃ{' '} {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}।
                                            <b style={{ fontWeight: 'bold' }}> বর্তমান ঠিকানা- </b>
                                            গ্রামঃ {' '}{certificate?.citizen_id?.present_address[0]?.village_bn_name}, 
                                            বাসা/হোল্ডিং {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.holding_no}`)}, 
                                            ওয়ার্ডঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.ward_no}`)},
                                            ডাকঘরঃ{' '} {certificate?.citizen_id?.present_address[0]?.post_office_bn}, 
                                            ইউনিয়নঃ{' '} {certificate?.citizen_id?.present_address[0]?.union?.bn_name},
                                            উপজেলাঃ {certificate?.citizen_id?.present_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                                            {certificate?.citizen_id?.present_address[0]?.district?.bn_name} {' '} আমার পরিচিত।  {' '}
                                            যদিও জন্মনিবন্ধন/জাতীয় পরিচয় পত্র নথিতে তাহার নাম <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b> এবং অন্য নথিতে <b style={{ fontWeight: 'bold' }}>{certificate?.samePerson?.name_bn}</b> উল্লেখিত রয়েছে, তিনি একই ব্যক্তি।

                                        </p>

                                        <br />

                                       {/*  <p  className={styles.mainText}>আমি তাহার উজ্জ্বল ভবিষ্যৎ ও সর্বাঙ্গিন মঙ্গল কামনা করি।</p> */}

                                    </div>
                                </div>
                                <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={true} />
                            </div>

                            <CertificatePagesLogo
                                certificate={certificate}
                                toValue={'200px'}
                            />
                        </div>
                    </div>


                </div>
            }


        </DashboardLayout>
    );
};

function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Bnattestationofthesameperson)

