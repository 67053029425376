import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUpdatemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";
import { CreateMunicipalityTradeLicenseSchema } from "../../../../helpers/validation/MunicipalitySchema";
import Button from "../../../button/Button";

const UpdateTradeLicenseMunicipality = ({ certificate }) => {
    const navigate = useNavigate()

    const institution_nature = [
        {
            bn_name: "যৌথ",
            en_name: "Joint",
            data_bn_name: "যৌথ",
            data_en_name: "Joint"
        },
        {
            bn_name: "একক",
            en_name: "Individual",
            data_bn_name: "একক",
            data_en_name: "Individual"
        },

        {
            bn_name: "অন্যান্য",
            en_name: "Others",
            data_bn_name: "অন্যান্য",
            data_en_name: "others"
        },
    ]
    const trade_licenses_types = [
        {
            bn_name: "নতুন",
            en_name: "New",
            data_bn_name: "নতুন",
            data_en_name: "New"
        },
        {
            bn_name: "নবায়ন",
            en_name: "Renewal",
            data_bn_name: "নবায়ন",
            data_en_name: "Renewal"
        },

        {
            bn_name: "সংশোধন",
            en_name: "Amendment",
            data_bn_name: "সংশোধন",
            data_en_name: "Amendment"
        },
    ]
    const [updateCertificate, { isLoading, isError, isSuccess, error }] = useUpdatemunicipalitycertificateMutation()
    //
    /* total ammount handel   */
    const [renewalfee, setRenewalfee] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.renewal_fee);
    const [misc, setMisc] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.misc);
    const [aboutincometax, setAboutincometax] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.about_income_tax);
    const [vat, setVat] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.vat);
    const [signboard, setSignboard] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.signboard);
    const [due, setDue] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.due);
    const [service_charge, setService_charge] = useState(certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.service_charge);
    const [institutionNature, setInstitutionNature] = useState(certificate?.trade_license_infomation[0]?.institution_nature[0]?.en_name)


    const calculateTotalPrices = () => {
        const totalServicePrices =
            Number(renewalfee) +
            Number(misc) +
            Number(aboutincometax) +
            Number(signboard) +
            Number(due) +
            Number(vat) +
            Number(service_charge);
        const totalPrices = totalServicePrices
        return totalPrices.toFixed(2);
    };

    const [totalPrices, setTotalPrices] = useState(calculateTotalPrices());

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(CreateMunicipalityTradeLicenseSchema) });



    const UpdateOnSubmit = async (certificateData) => {
        const institutionNature = document.querySelector(
            'select[name="institution_nature"] option:checked'
        );
        const tradeLicensesTypes = document.querySelector(
            'select[name="trade_licenses_types"] option:checked'
        );
        const trade_license_infomation = [
            {
                license_id: certificateData?.license_id,
                institution_bn_name: certificateData?.institution_bn_name,
                institution_en_name: certificateData?.institution_en_name,
                institution_bn_type: certificateData?.institution_bn_type,
                institution_en_type: certificateData?.institution_en_type,
                circle_street_mohalla_bn_name: certificateData?.circle_street_mohalla_bn_name,
                circle_street_mohalla_en_name: certificateData?.circle_street_mohalla_en_name,
                mobile_number: certificateData?.mobile_number,
                institution_nature: {
                    bn_name: institutionNature.getAttribute("data-bn_name"),
                    en_name: institutionNature.getAttribute("data-en_name"),
                    joint_name_bn: certificateData?.joint_name_bn,
                    joint_name_en: certificateData?.joint_name_en,
                    joint_nid: certificateData?.joint_nid,
                },
                trade_licenses_types: {
                    bn_name: tradeLicensesTypes.getAttribute("data-bn_name"),
                    en_name: tradeLicensesTypes.getAttribute("data-en_name"),
                },
                license_fee_infomation: {
                    renewal_fee: certificateData?.renewal_fee,
                    signboard: certificateData?.signboard,
                    misc: certificateData?.misc,
                    due: certificateData?.due,
                    service_charge: certificateData?.service_charge,
                    about_income_tax: certificateData?.about_income_tax,
                    vat: certificateData?.vat,
                    totalAmmount: totalPrices,
                },
                business_commencement_date: certificateData?.business_commencement_date,
                tin: certificateData?.tin,
                bin: certificateData?.bin,
                institution_address_en: certificateData?.institution_address_en,
                institution_address_bn: certificateData?.institution_address_bn,
            },
        ];
        const certificateDataInfo = {
            id: certificate?._id,
            data: { trade_license_infomation: trade_license_infomation }
        };
        await updateCertificate(certificateDataInfo);
    }


    useEffect(() => {
        if (isSuccess) {
            toast.success('আপডেট করা হয়েছে')
            navigate('/application-certificate/municipality/trade-license')
        }
        if (error) {
            toast.error('আপডেট করা নেই')
        }

        setTotalPrices(calculateTotalPrices());
    }, [renewalfee, vat, misc, signboard, due, service_charge, aboutincometax, error, isError, isSuccess]);

    return (
        <>
            <form onSubmit={handleSubmit(UpdateOnSubmit)}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div>
                                    <h5>প্রতিষ্ঠানের তথ্য : </h5>
                                    <div className="row gx-2">
                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                লাইসেন্স আইডি{" "}
                                                <span className="from_required">*</span>{" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="লাইসেন্স আইডি"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.license_id}
                                                {...register("license_id")}
                                            />
                                            {errors?.license_id && (
                                                <span className="form__error">
                                                    {errors?.license_id.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের নাম (বাংলায়){" "}
                                                <span className="from_required">*</span>{" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম বাংলা"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.institution_bn_name}
                                                {...register("institution_bn_name")}
                                            />
                                            {errors?.institution_bn_name && (
                                                <span className="form__error">
                                                    {errors?.institution_bn_name.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের নাম: (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম ইংরেজিতে"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.institution_en_name}
                                                {...register("institution_en_name")}
                                            />
                                            {errors?.institution_en_name && (
                                                <span className="form__error">
                                                    {errors?.institution_en_name.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                সার্কেল / রাস্তা / মহল্লা নাম: (বাংলা){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম বাংলা"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.circle_street_mohalla_bn_name}
                                                {...register("circle_street_mohalla_bn_name")}
                                            />
                                            {errors?.circle_street_mohalla_bn_name && (
                                                <span className="form__error">
                                                    {errors?.circle_street_mohalla_bn_name.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                সার্কেল / রাস্তা / মহল্লা  নাম: (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম ইংরেজিতে"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.circle_street_mohalla_en_name}
                                                {...register("circle_street_mohalla_en_name")}
                                            />
                                            {errors?.circle_street_mohalla_en_name && (
                                                <span className="form__error">
                                                    {errors?.circle_street_mohalla_en_name.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                ফোন / মোবাইল নং:
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="মোবাইল নাম্বার"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.mobile_number}
                                                {...register("mobile_number")}
                                            />
                                            {errors?.mobile_number && (
                                                <span className="form__error">
                                                    {errors?.mobile_number.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের ধরণ (বাংলায়){" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম বাংলায়"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.institution_bn_type}
                                                {...register("institution_bn_type")}
                                            />
                                            {errors?.institution_bn_type && (
                                                <span className="form__error">
                                                    {errors?.institution_bn_type.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের ধরণ (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম ইংরেজিতে"
                                                defaultValue={certificate?.trade_license_infomation
                                                [0]?.institution_en_type}
                                                {...register("institution_en_type")}
                                            />
                                            {errors?.institution_en_type && (
                                                <span className="form__error">
                                                    {errors?.institution_en_type.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label
                                                htmlFor="Promo_amount"
                                                className="form-label"
                                            >
                                                প্রতিষ্ঠানের প্রকৃতি{" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("institution_nature")}
                                                onChange={(e) => setInstitutionNature(e.target.value)}
                                            >
                                                <option value="">
                                                    প্রতিষ্ঠানের প্রকৃতি নির্বাচন করুন।
                                                </option>
                                                {
                                                    institution_nature?.map((data) => <option
                                                        selected={data?.en_name === certificate?.trade_license_infomation[0]?.institution_nature[0]?.en_name}
                                                        data-bn_name={data?.data_bn_name}
                                                        data-en_name={data?.en_name}
                                                        value={data?.en_name}
                                                    >{data?.bn_name}({data?.en_name})
                                                    </option>)
                                                }
                                            </select>
                                            {errors?.institution_nature && (
                                                <span className="form__error">
                                                    {errors?.institution_nature.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label
                                                htmlFor="Promo_amount"
                                                className="form-label"
                                            >
                                                ট্রেড লাইসেন্স এর ধরণ
                                                <span className="from_required">*</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("trade_licenses_types")}
                                            >
                                                <option value="">
                                                    ট্রেড লাইসেন্স এর ধরণ নির্বাচন করুন।
                                                </option>

                                                {
                                                    trade_licenses_types?.map((data) => <option
                                                        selected={data?.en_name == certificate?.trade_license_infomation[0]?.trade_licenses_types[0]?.en_name}
                                                        data-bn_name={data?.data_bn_name}
                                                        data-en_name={data?.en_name}

                                                    >{data?.bn_name}({data?.en_name})
                                                    </option>)
                                                }
                                            </select>
                                            {errors?.trade_licenses_types && (
                                                <span className="form__error">
                                                    {errors?.trade_licenses_types.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                TIN নম্বর (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={certificate?.trade_license_infomation[0]?.tin}
                                                placeholder="নাম ইংরেজিতে"
                                                {...register("tin")}
                                            />
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <label className="form-label">
                                                BIN নম্বর (ইংরেজিতে){" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={certificate?.trade_license_infomation[0]?.bin}
                                                placeholder="নাম ইংরেজিতে"
                                                {...register("bin")}
                                            />
                                            {errors?.bin && (
                                                <span className="form__error">
                                                    {errors?.bin.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের ঠিকানার (বাংলায়) {" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম বাংলায়"
                                                defaultValue={certificate?.trade_license_infomation[0]?.institution_address_bn}
                                                {...register("institution_address_bn")}
                                            />
                                            {errors?.institution_address_bn && (
                                                <span className="form__error">
                                                    {errors?.institution_address_bn.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <label className="form-label">
                                                প্রতিষ্ঠানের ঠিকানার (ইংরেজিতে) {" "}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="নাম ইংরেজিতে"
                                                defaultValue={certificate?.trade_license_infomation[0]?.institution_address_en}
                                                {...register("institution_address_en")}
                                            />
                                            {errors?.institution_address_en && (
                                                <span className="form__error">
                                                    {errors?.institution_address_en.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {
                                        institutionNature === "Joint" && <section className="row gx-2">

                                            <div className="col-lg-6 mb-3">
                                                <label className="form-label">
                                                    বাকী স্বত্ত্বাধিকারীগণের নাম (বাংলায়) {" "}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="নাম বাংলায়"
                                                    defaultValue={certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_name_bn}
                                                    {...register("joint_name_bn")}
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label className="form-label">
                                                    বাকী স্বত্ত্বাধিকারীগণের নাম (ইংরেজিতে) {" "}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="নাম ইংরেজিতে"
                                                    defaultValue={certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_name_en}
                                                    {...register("joint_name_en")}
                                                />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label className="form-label">
                                                    বাকী স্বত্ত্বাধিকারীগণের জাতীয় পরিচয় পত্র/জন্মনিবন্ধন নং
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="বাকী স্বত্ত্বাধিকারীগণের জাতীয় পরিচয় পত্র/জন্মনিবন্ধন নং"
                                                    defaultValue={certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_nid}
                                                    {...register("joint_nid")}
                                                />
                                            </div>
                                        </section>
                                    }
                                </div>
                                <div>
                                    <h5>লাইসেন্স তথ্য: </h5>
                                    <div className="row gx-2">
                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                ট্রেড লাইসেন্স/নবায়ন ফি{" "}
                                                <span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="0.00"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.renewal_fee}
                                                {...register("renewal_fee")}
                                                onChange={(e) => setRenewalfee(e.target.value)}
                                            />
                                            {errors?.renewal_fee && (
                                                <span className="form__error">
                                                    {errors?.renewal_fee.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                সাইনবোর্ড/বিজ্ঞাপন কর
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="0.00"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.signboard}
                                                {...register("signboard")}
                                                onChange={(e) => setSignboard(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                বিবিধ
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="0.00"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.misc}
                                                {...register("misc")}
                                                onChange={(e) => setMisc(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">বকেয়া</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.due}
                                                placeholder="0.00"
                                                {...register("due")}
                                                onChange={(e) => setDue(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-3 mb-3">
                                            <label className="form-label">সার চার্জ/জরিমানা </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.service_charge}
                                                placeholder="0.00"
                                                {...register("service_charge")}
                                                onChange={(e) =>
                                                    setService_charge(e.target.value)
                                                }
                                            />
                                        </div>

                                        <div className="col-3 mb-3">
                                            <label className="form-label">আয়কর বাবদ </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.about_income_tax}
                                                placeholder="0.00"
                                                {...register("about_income_tax")}
                                                onChange={(e) =>
                                                    setAboutincometax(e.target.value)
                                                }
                                            />
                                        </div>

                                        <div className="col-3 mb-3">
                                            <label className="form-label">
                                                ভ্যাট
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="0.00"
                                                defaultValue={certificate?.trade_license_infomation[0]?.license_fee_infomation[0]?.vat}
                                                {...register("vat")}
                                                onChange={(e) => setVat(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-3 mb-3">
                                            <label className="form-label">মোট</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="0.00"
                                                value={totalPrices}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='p-2'
                                style={{
                                    width: '100%',
                                    textAlign: 'end'
                                }}>
                                <Button
                                    btnClass='w-100'
                                    isLoading={isLoading}
                                    text="  সংশোধন করুন"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}


export default UpdateTradeLicenseMunicipality