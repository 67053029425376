import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styles from './EnBarshikAyerCertificate.module.css';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { connect } from 'react-redux';
import { CertificateFooterEn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderEn } from '../../CertificateHeader/CertificateHeader';
import { RefetchData } from '../../../RefetchData';
import { useEffect } from 'react';
import { useState } from 'react';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';

const EnBarshikAyerCertificate = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch, isLoading]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}>
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderEn certificate={certificate} sonodName={'Annual Income Certificate'} />
                  <div>
                    <p className={styles.mainText}>
                      This is to certify that, <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id ?.name_english}</b>,
                      Father/Husbend:{' '}
                      <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_english}</b>, Mother:{' '}
                      <b style={{ fontWeight: 'bold' }}> {certificate?.citizen_id?.mother_name_english}</b>,

                      Village / House:{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.village_en_name}, Post Office:{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.post_office_en}, Ward No: {certificate?.citizen_id?.permanent_address[0]?.ward_no},
                      Upazila: {" "}
                      {certificate?.citizen_id?.permanent_address[0]?.upazila?.name}, District:{' '}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.name}. According to my knowledge, her
                      yearly income is BDT {certificate?.income?.year} Tk only.
                    </p>
                    <br />

                    <div className={styles.mainText}>
                      <p>I wish her all the best and a bright future. </p>
                    </div>
                  </div>

                </div>
                <CertificateFooterEn certificate={certificate} accountType={accountType} marginHandel={true} />
              </div>
              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>


        </div>
      }

    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(EnBarshikAyerCertificate)