import React, { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OtherCertificateSchema } from "../../../../helpers/validation/AllSonodCertificateSchema";
import { useUpdatemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";
import Button from "../../../button/Button";
function EditOtherCertificatemunicipality({ certificate }) {
  const navigate = useNavigate();
  const bodyData = useRef(null);
  const [joditdetails, setJoditdetails] = useState({
    detail_bn: certificate?.other_info?.detail_bn,
    detail_en: certificate?.other_info?.detail_en,
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(OtherCertificateSchema) });

  const [
    updateCertificate,
    { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err },
  ] = useUpdatemunicipalitycertificateMutation();

  const createOnSubmit = async (certificateData) => {
    const otherInfo = {
      ...certificateData,
      ...joditdetails,
    };
    const certificateDataInfo = {
      id: certificate?._id,
      data: { other_info: otherInfo },
    };

    await updateCertificate(certificateDataInfo);
  };

  const contentFieldChanagedbn = (data) => {
    setJoditdetails({
      detail_bn: data,
      detail_en: joditdetails?.detail_en,
    });
  };
  const contentFieldChanageden = (data) => {
    setJoditdetails({
      detail_bn: joditdetails?.detail_bn,
      detail_en: data,
    });
  };

  useEffect(() => {
    if (isSucc) {
      toast.success("সঠিকভাবে  আপডেট করা হয়েছ");
      navigate('/application-certificate/municipality/other')
      reset();
    }
    if (isErr) {
        toast.error('আপডেট করা নেই')
    }
  }, [isSucc, isErr, err]);

  return (
    <>
      <form onSubmit={handleSubmit(createOnSubmit)}>
        <div className="col-lg-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="row gx-2">
                <div className="col-6 mb-3">
                  <label className="form-label">
                    নাম: (বাংলা) <span className="from_required">*</span>{" "}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="নাম বাংলা"
                    value={certificate?.citizen_id?.name_bangla}
                    disabled
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    নাম: (ইংরেজিতে) <span className="from_required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="নাম ইংরেজিতে"
                    value={certificate?.citizen_id?.name_english}
                    disabled
                  />
                </div>
              </div>
              <div className="row gx-2">
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    মোবাইল নম্বর <span className="from_required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="মোবাইল নম্বর"
                    value={certificate?.citizen_id?.number}
                    disabled
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    জাতীয় পরিচয়পত্র নং{" "}
                    <span className="from_required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="জাতীয় পরিচয়পত্র নং"
                    value={certificate?.citizen_id?.nid}
                    disabled
                  />
                </div>
              </div>
              <div className="row gx-2">
                <div className="col-lg-6 mb-3">
                  <label className="form-label">
                    শিরোনাম নাম: (বাংলা){" "}
                    <span className="from_required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" শিরোনাম নাম"
                    {...register("title_bn")}
                    defaultValue={certificate?.other_info?.title_bn}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label">শিরোনাম নাম: (ইংরেজিতে) </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" শিরোনাম নাম"
                    {...register("title_en")}
                    defaultValue={certificate?.other_info?.title_en}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="form-label">
                  বিস্তারিত লিখুন : (বাংলা){" "}
                  <span className="from_required">*</span>
                </label>
                <JoditEditor
                  ref={bodyData}
                  value={joditdetails?.detail_bn}
                  onChange={(newContent) => contentFieldChanagedbn(newContent)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="form-label">
                  বিস্তারিত লিখুন : (ইংরেজিতে){" "}
                </label>
                <JoditEditor
                  ref={bodyData}
                  value={joditdetails?.detail_en}
                  onChange={(newContent) => contentFieldChanageden(newContent)}
                />
              </div>
            </div>
          </div>
        </div>
        <Button isLoading={loading} text="জমা করুন" />
      </form>
    </>
  );
}

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users };
}

export default connect(mapStateToProps, null)(EditOtherCertificatemunicipality);
