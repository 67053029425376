import { useParams } from "react-router-dom";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import EditOtherCertificatemunicipality from "../../../../components/allCertificates/municipality/other/EditOtherCertificatemunicipality";

const OtherMunicipalityEditPage = () => { 
  let content;
  const { id } = useParams();
  const {
    data: certificate,
    isLoading,
    isError,
  } = useGetsinglemunicipalitycertificateQuery(id);

  if (!certificate || isLoading || isError) {
    content = <div>অপেক্ষা করুন....</div>;
  } else {
    content = <EditOtherCertificatemunicipality certificate={certificate?.certificate} />;
  }

  return <DashboardLayout>{content}</DashboardLayout>;
};
export default OtherMunicipalityEditPage;
