import EnglishToBanglaNumber from "../EnglishToBanglaNumber";

export default function FiscalSingleYear(type,value) {
    let format;
    let date = new Date();
    date.setMonth(date.getMonth() - 6);
    let year = date.getFullYear();
    const handelDate = `${year + value}`
    if (type == 'bangla') {
        format = EnglishToBanglaNumber(handelDate)
    } else {
        format = handelDate
    }
    return format;
}


