const EnglishToBanglaNumber = (englishNumber) => {
    if (englishNumber === 'undefined') {
        return '০'
    } else {
        const englishToBanglaMap = {
            '0': '০',
            '1': '১',
            '2': '২',
            '3': '৩',
            '4': '৪',
            '5': '৫',
            '6': '৬',
            '7': '৭',
            '8': '৮',
            '9': '৯',
        };
        let banglaNumber = '';
        for (let i = 0; i < englishNumber?.length; i++) {
            const englishDigit = englishNumber[i];
            if (englishToBanglaMap.hasOwnProperty(englishDigit)) {
                banglaNumber += englishToBanglaMap[englishDigit];
            } else {
                banglaNumber += englishDigit;
            }
        }
        return banglaNumber;
    }


}
// data and any  number

export default EnglishToBanglaNumber;

