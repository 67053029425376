import { fetchBaseQuery } from "@reduxjs/toolkit/query";
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const { userInfo: user } = getState().auth;
      if (user?.user?.accessToken) {
        headers.set("Authorization", `Bearer ${user?.user?.accessToken}`);
      }
      return headers;
    },
  });
  
  export default baseQuery;
