import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQueryHandel';
export const SubscriptionApi = createApi({
  reducerPath: 'Subscription',
  tagTypes: ['Subscription'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSubscription: builder.query({
      query: (pathname) => ({
        url: `v1/subscription?${pathname}`,
        method: "GET",
      }),
      providesTags: ["Subscription"],
    }),
    postSubscription: builder.mutation({
      query: (data) => ({
        url: `v1/subscription/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Subscription'],
    }),
    updateSubscription: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/subscription/?_id=${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Subscription'],
    }),



  }),
});
export const { usePostSubscriptionMutation, useGetSubscriptionQuery, useUpdateSubscriptionMutation } = SubscriptionApi;
